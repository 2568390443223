import * as echarts from "echarts";

//hooks
import { useRef, useEffect } from "react";
import { useChartBuilder } from "../contexts/ChartBuilderContext";

//types
import { ChartConfig, OptionExtras } from "../modules/charts/types";

//components
import Loader from "./Loader";

//ui componets
import { Box } from "@mui/material";

//utils
import { emptyChartOption, initialChartOption } from "../modules/charts/common";
import { chartDefs } from "../modules/charts";
import { debounce } from "../utils/common";

interface IEChart {
  rows?: any[] | null;
  loading: boolean;
  chartConfig: ChartConfig;
  optionExtras?: OptionExtras;
}

export default function EChart({
  rows,
  loading,
  chartConfig,
  optionExtras,
}: IEChart) {
  const chartDom = useRef(null);

  const { chartType, hasRequiredValues, getLabels } = useChartBuilder();

  useEffect(() => {
    if (!chartDom.current) return;

    let chartInstance: echarts.ECharts | null | undefined = null;

    // Function to initialize or update the chart
    const initializeChart = () => {
      if (chartDom.current) {
        // Check if there's an existing instance and dispose of it
        chartInstance = echarts.getInstanceByDom(chartDom.current);
        if (chartInstance) {
          chartInstance.dispose();
        }

        // Initialize the chart
        chartInstance = echarts.init(chartDom.current);

        if (!hasRequiredValues() || !rows) {
          chartInstance.clear();
          chartInstance.setOption(initialChartOption);
          return;
        }

        const chartDef = chartDefs[chartType];
        if (chartDef.builder !== "echart") return;

        const echartOption =
          rows.length === 0
            ? emptyChartOption
            : chartDef.buildChartOption(
                getLabels(),
                rows,
                chartConfig,
                optionExtras
              );

        chartInstance.clear();
        chartInstance.setOption(echartOption);
      }
    };

    // Initialize or update the chart
    initializeChart();

    const resizeHandler = () => {
      if (chartInstance) chartInstance.resize();
    };

    const debouncedResizeHandler = debounce(resizeHandler, 300);
    window.addEventListener("resize", debouncedResizeHandler);

    return () => {
      if (chartInstance) chartInstance.dispose();

      window.removeEventListener("resize", debouncedResizeHandler);
    };
    // eslint-disable-next-line
  }, [rows, chartType]);

  return (
    <Box sx={{ width: `100%`, height: `100%` }}>
      {loading && <Loader />}
      <Box ref={chartDom} sx={{ width: `100%`, height: `100%` }}></Box>
    </Box>
  );
}
