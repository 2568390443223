import dayjs from "dayjs";
import { CustomDashboardProps } from "../utils/types";
import { TableColumnControlGroupValue } from "../modules/charts/types";
import {
  convertToDate,
  formatAmount,
  getAllocationDetails,
  getDefaultCheckboxWhereClause,
  getDefaultValueWhereClause,
  getDefaultValuesWhereClause,
  getLast12Months,
} from "../utils/dashboard-utils";
import { isNumber } from "../utils/common";

export const dashboard: CustomDashboardProps = {
  title: "EVA Trends",
  charts: [
    {
      id: "1",
      datasetId: "9",
      chartType: "table",
      title: "EVA Trends",
      preControls: {
        "table-columns": [
          {
            column: "email",
            label: "Email",
            parentColumns: [],
            width: 150,
            hidden: true,
          },
          {
            column: "employee",
            label: "Employee",
            parentColumns: ["email"],
            width: 200,
          },
          {
            column: "current_grade",
            label: "Current Grade",
            parentColumns: ["employee", "email"],
            width: 150,
          },
          {
            column: "allocation_details",
            label: "Allocation",
            parentColumns: ["current_grade", "employee", "email"],
            width: 350,
            calculate: getAllocationDetails,
          },
          {
            column: "grade_level_salary",
            label: "Expected Amount",
            parentColumns: ["current_grade", "employee", "email"],
            width: 150,
            formatValue: formatAmount,
          },
          ...getLast12Months().map<TableColumnControlGroupValue>((_month) => ({
            column: _month.col.toUpperCase(),
            label: _month.label.toUpperCase(),
            parentColumns: null,
            width: 100,
            formatValue: formatAmount,
            getCellStyles(row, value, theme) {
              const isNumberValue = isNumber(value);
              const bgColor =
                isNumberValue && row["is_positive_trend"]
                  ? "rgb(222, 255, 225)"
                  : isNumberValue && row["is_negative_trend"]
                  ? "rgb(255, 222, 223)"
                  : theme.palette.background.paper;
              return {
                backgroundColor: bgColor,
              };
            },
          })),
        ],
      },
      chartConfig: {
        sortType: "none",
      },
      layoutId: "1",
      layout: { x: 0, y: 0, w: 12, h: 6, i: "1" },
    },
  ],
  filters: [
    {
      id: "1",
      column: "emp_name_email",
      name: "Employee",
      type: "values",
      required: false,
      value: [],
      defaultValue: [],
      appliesToChartIds: ["1"],
      cascadedFilterIds: [],
      requiredFilterIds: [],
      options: null,
      getWhereClause: getDefaultValuesWhereClause,
    },
    {
      id: "2",
      column: "current_grade",
      name: "Grade",
      type: "value",
      required: false,
      value: null,
      defaultValue: null,
      appliesToChartIds: ["1"],
      cascadedFilterIds: [],
      requiredFilterIds: [],
      options: null,
      getWhereClause: getDefaultValueWhereClause,
    },
    {
      id: "3",
      column: "allocation_type",
      name: "Allocation Type",
      type: "value",
      required: false,
      value: null,
      defaultValue: null,
      appliesToChartIds: ["1"],
      cascadedFilterIds: [],
      requiredFilterIds: [],
      options: null,
      getWhereClause: getDefaultValueWhereClause,
    },
    {
      id: "4",
      column: "calculated_for",
      name: "From (Month)",
      type: "value",
      required: false,
      value: null,
      defaultValue: null,
      appliesToChartIds: ["1"],
      cascadedFilterIds: [],
      requiredFilterIds: [],
      options: getLast12Months().map((_month) => _month.label),
      formatOptions: (options) =>
        options.map((date) => dayjs(date).format("MMM YYYY")),
      getWhereClause(params, value) {
        if (params.type !== "value" || value === null) return null;
        return {
          column: params.column,
          operator: "gte",
          value: convertToDate(value as string),
        };
      },
    },
    {
      id: "5",
      column: "calculated_for",
      name: "To (Month)",
      type: "value",
      required: false,
      value: null,
      defaultValue: null,
      appliesToChartIds: ["1"],
      cascadedFilterIds: [],
      requiredFilterIds: ["4"],
      options: getLast12Months().map((_month) => _month.label),
      formatOptions: (options) =>
        options.map((date) => dayjs(date).format("MMM YYYY")),
      getWhereClause(params, value) {
        if (params.type !== "value" || value === null) return null;
        return {
          column: params.column,
          operator: "lte",
          value: convertToDate(value as string),
        };
      },
    },
    {
      id: "6",
      column: "virtual_team",
      name: "VT",
      type: "value",
      required: false,
      value: null,
      defaultValue: null,
      appliesToChartIds: ["1"],
      cascadedFilterIds: [],
      requiredFilterIds: [],
      options: null,
      getWhereClause: getDefaultValueWhereClause,
    },
    {
      id: "7",
      column: "sub_virtual_team",
      name: "Sub-VT",
      type: "value",
      required: false,
      value: null,
      defaultValue: null,
      appliesToChartIds: ["1"],
      cascadedFilterIds: [],
      requiredFilterIds: [],
      options: null,
      getWhereClause: getDefaultValueWhereClause,
    },
    {
      id: "8",
      column: "is_negative_trend",
      name: "Show only Concerns",
      type: "checkbox",
      required: false,
      value: false,
      defaultValue: false,
      appliesToChartIds: ["1"],
      cascadedFilterIds: [],
      requiredFilterIds: [],
      disablingFilterIds: ["9"],
      option: "true",
      getWhereClause: getDefaultCheckboxWhereClause,
    },
    {
      id: "9",
      column: "is_positive_trend",
      name: "Show only Posistive Trends",
      type: "checkbox",
      required: false,
      value: false,
      defaultValue: false,
      appliesToChartIds: ["1"],
      cascadedFilterIds: [],
      requiredFilterIds: [],
      disablingFilterIds: ["8"],
      option: "true",
      getWhereClause: getDefaultCheckboxWhereClause,
    },
  ],
};
