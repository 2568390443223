import React from "react";
import ace, { Ace } from "ace-builds";
import axiosConfig from "../utils/axiosConfig";
import { baseUrl } from "../config";

interface Table {
  type: string;
  value: string;
}

interface Column {
  type: string;
  value: string;
}

interface SqlLabContextType {
  schemas: string[];
  tables: Table[];
  columns: Column[];
  currentSchema: string | null;
  currentTable: string | null;
  handleDatabaseChange: (id: number | null) => void;
  handleSchemaChange: (e: any) => void;
  handleTableChange: (e: any) => void;
}

const getCompleter = (
  values: string[],
  type: "Schema" | "Table" | "Column"
) => {
  const completions = values.map(
    (value) =>
      ({
        caption: value,
        value: value,
        meta: type,
      } as Ace.Completion)
  );
  return {
    getCompletions: (
      editor: Ace.Editor,
      session: Ace.EditSession,
      pos: Ace.Point,
      prefix: string,
      callback: Ace.CompleterCallback
    ): void => {
      callback(null, completions);
    },
  };
};

// Create the sql lab context
const SqlLabContext = React.createContext<SqlLabContextType | null>(null);

// Custom hook to access the sql lab context
export const useSqlLab = () => {
  const context = React.useContext(SqlLabContext);
  if (!context) {
    throw new Error("useSqlLab must be used within an SqlLabProvider");
  }
  return context;
};

// SqlLabProvider component to wrap your application with
export const SqlLabProvider = ({ children }: { children: any }) => {
  const [databaseId, setDatabaseId] = React.useState<number | null>(null);

  const [schemas, setSchemas] = React.useState<string[]>([]);
  const [currentSchema, setCurrentSchema] = React.useState<string | null>(null);

  const [tables, setTables] = React.useState<Table[]>([]);
  const [currentTable, setCurrentTable] = React.useState<string | null>(null);

  const [columns, setColumns] = React.useState<Column[]>([]);

  const handleDatabaseChange = (id: number | null) => {
    if (id === databaseId) return;
    setDatabaseId(id);
  };

  const handleSchemaChange = (e: any) => {
    if (e.target.value === currentSchema) return;
    setColumns([]);
    setCurrentTable(null);
    setTables([]);
    setCurrentSchema(e.target.value as string);
  };

  const handleTableChange = (e: any) => {
    if (e.target.value === currentTable) return;
    setColumns([]);
    setCurrentTable(e.target.value as string);
  };

  React.useEffect(() => {
    if (!currentSchema || !currentTable) return;
    //fetch columns in current table
    const fetchColumns = async () => {
      try {
        const response = await axiosConfig({
          method: "GET",
          url: `${baseUrl}/sqllab/columns/?schema_name=${currentSchema}&table=${currentTable}`,
        });

        if (response.status !== 200)
          throw new Error("Unable to fetch schemas", response.data);

        if (typeof response.data !== "object" || !response.data.columns)
          throw new Error("Unable to parse data", response.data);

        const _columns: Column[] = response.data.columns;
        setColumns(_columns);

        const langTools = ace.require("ace/ext/language_tools");
        langTools.addCompleter(
          getCompleter(
            _columns.map((_col) => _col.value),
            "Column"
          )
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchColumns();
  }, [currentSchema, currentTable]);

  React.useEffect(() => {
    if (!currentSchema) return;
    //fetch tables in current schema
    const fetchTables = async () => {
      try {
        const response = await axiosConfig({
          method: "GET",
          url: `${baseUrl}/sqllab/tables/?schema_name=${currentSchema}`,
        });

        if (response.status !== 200)
          throw new Error("Unable to fetch tables", response.data);
        if (typeof response.data !== "object")
          throw new Error("Unable to parse data", response.data);

        const _tables: Table[] = response.data.tables;
        setTables(_tables);

        const langTools = ace.require("ace/ext/language_tools");
        langTools.addCompleter(
          getCompleter(
            _tables.map((_table) => _table.value),
            "Table"
          )
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchTables();
  }, [currentSchema]);

  React.useEffect(() => {
    //fetch schemas
    const fetchSchemas = async () => {
      try {
        const response = await axiosConfig({
          method: "GET",
          url: `${baseUrl}/sqllab/schemas/`,
        });

        if (response.status !== 200)
          throw new Error("Unable to fetch schemas", response.data);
        if (typeof response.data !== "object")
          throw new Error("Unable to parse data", response.data);

        const _schemas: string[] = response.data.schemas;
        setSchemas(_schemas);

        //add completer
        const langTools = ace.require("ace/ext/language_tools");
        langTools.addCompleter(getCompleter(_schemas, "Schema"));
      } catch (error) {
        console.error(error);
      }
    };
    fetchSchemas();
  }, []);

  return (
    <SqlLabContext.Provider
      value={{
        schemas,
        tables,
        columns,
        currentSchema,
        currentTable,
        handleDatabaseChange,
        handleSchemaChange,
        handleTableChange,
      }}
    >
      {children}
    </SqlLabContext.Provider>
  );
};
