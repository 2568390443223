import { ChartDefs } from "./types";
import { tableChartDef } from "./chart-types/table";
import { barChartDef } from "./chart-types/bar";
import { lineChartDef } from "./chart-types/line";
import { pieChartDef } from "./chart-types/pie";
import { bigTextChartDef } from "./chart-types/big-text";

export const chartDefs: ChartDefs = {
  bar: barChartDef,
  line: lineChartDef,
  pie: pieChartDef,
  "big-text": bigTextChartDef,
  table: tableChartDef,
};
