import { useState } from "react";
import { Box, Stack, Button } from "@mui/material";
import { ControlId, IMenuItem } from "../modules/charts/types";
import { useChartBuilder } from "../contexts/ChartBuilderContext";
import ControlInputField from "./ControlInputField";

interface ControlInputFormProps {
  colItems: IMenuItem[];
  controlId: ControlId;
  inputGroupIdx: number;
  handleClose: () => void;
}

export default function ControlInputForm({
  colItems,
  controlId,
  inputGroupIdx,
  handleClose,
}: ControlInputFormProps) {
  const { controls, updateControlGroupValue } = useChartBuilder();

  const control = controls[controlId]!;
  const inputGroup = control.inputGroups[inputGroupIdx];

  const [values, setValues] = useState<{ [key in string]: any }>(
    Object.values(inputGroup.inputs).reduce((acc, input) => {
      if (input.type === "multi-select") {
        acc[input.id] = input.value.map((_value: string) => ({
          value: _value,
          label: _value,
        }));
      } else {
        acc[input.id] = input.value;
      }
      return acc;
    }, {})
  );

  function changeValue(_id: any, _value: any) {
    setValues((prevValues) => {
      const newValues = { ...prevValues };
      newValues[_id] = _value;
      return newValues;
    });
  }

  function onLabelFocus() {
    if (values.label !== "") return;
    setValues((prevValues) => {
      const newValues = { ...prevValues };
      newValues.label =
        newValues.aggregation && inputGroup.groupType === "metrics"
          ? `${newValues.aggregation}(${newValues.column})`
          : `${newValues.column}`;
      return newValues;
    });
  }

  function handleSave(e: any) {
    e.preventDefault();

    const controlGroupValue: any = {
      column: values.column,
      label: values.label,
    };
    if (inputGroup.groupType === "metrics")
      controlGroupValue.aggregation = values.aggregation;
    if (inputGroup.groupType === "table-column") {
      controlGroupValue.parentColumns = values.parentColumns.map(
        (menuItem: any) => menuItem.value
      );
      controlGroupValue.width = values.width;
    }

    updateControlGroupValue(controlId, inputGroupIdx, controlGroupValue);
    handleClose();
  }

  return (
    <Box my={1} sx={{ width: "100%" }}>
      <form onSubmit={handleSave}>
        <Stack direction="column" spacing={2} mt={2}>
          {Object.values(inputGroup.inputs).map((input) => (
            <ControlInputField
              key={input.id}
              colItems={colItems}
              input={input}
              values={values}
              changeValue={changeValue}
              onLabelFocus={onLabelFocus}
            ></ControlInputField>
          ))}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          mt={2}
        >
          <Button type="button" variant="outlined" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </Stack>
      </form>
    </Box>
  );
}
