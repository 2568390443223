import { useEffect, useMemo, useState, MouseEvent } from "react";
import {
  Menu,
  Box,
  IconButton,
  Stack,
  Typography,
  useTheme,
  Select,
  MenuItem,
} from "@mui/material";

import Breadcrumbs from "./Breadcrumbs";
import Section from "./Section";

import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useData } from "../contexts/DataContext";
import { useApp } from "../contexts/AppContext";

import { DashboardInfo } from "../utils/types";
import MultiLevelDropdown from "./FinancialAnalysis/components/MultiLevelDropdown";
import { Option } from "./FinancialAnalysis/types";
import { reportType } from "./FinancialAnalysis/constants";
import ToggleSwitch from "./FinancialAnalysis/components/ToggleSwitch";

export interface IDashboardHeaderProps {
  dashboardInfo: DashboardInfo | null;
  hideBreadcrumbs?: boolean;
  hideSectionMenu?: boolean;
  hidePreviousYearsMenu?: boolean;
  hideMonthWiseToggle?: boolean;
}

export default function DashboardHeader({
  dashboardInfo,
  hideBreadcrumbs = false,
  hideSectionMenu = false,
  hidePreviousYearsMenu = true,
  hideMonthWiseToggle = true,
}: IDashboardHeaderProps) {
  const theme = useTheme();

  const { dashboardList } = useData();
  const { setSelectedItem, setSelectedFilter, setMonthToggle, monthToggle } =
    useApp();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const [selectedValue, setSelectedValue] = useState(1);
  const [dashboardInfoState, setDashboardInfoState] = useState(dashboardInfo);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<
    string | null
  >(null);
  const [selectedLevel1, setSelectedLevel1] = useState<Option | null>(null);
  const [selectedLevel2, setSelectedLevel2] = useState<Option | null>(null);
  const yearList = [1, 2, 3, 4, 5];

  const handleChange = (event: any) => {
    const selectedValue = event.target.value as number;
    setSelectedValue(selectedValue);
    setSelectedItem(selectedValue.toString());
  };

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggleChange = () => {
    setSelectedValue(yearList[0]);
    setSelectedItem(yearList[0].toString());
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const sectionDashboards = useMemo(
    () =>
      dashboardList.filter(({ section }) => section === dashboardInfo?.section),
    [dashboardInfo, dashboardList]
  );

  const setInitialData = () => {
    if (dashboardInfo?.id !== undefined) {
      setDashboardInfoState({
        ...dashboardInfo,
        ...{ breadcrumps_sections: [], custom_title: undefined },
      });
    }
  };

  useEffect(() => {
    handleMenuClose();
  }, [dashboardInfo]);

  useEffect(() => {
    handleToggleChange();
  }, [monthToggle]);

  useEffect(() => {
    if (dashboardInfo?.id) {
      if (
        (selectedLevel1?.value && selectedLevel2?.value) ||
        selectedLevel1?.label === reportType.overview
      ) {
        setSelectedFilter({
          selectedLevel1,
          selectedLevel2,
        });
        if (
          selectedLevel1.label &&
          selectedLevel1.label !== reportType.overview
        ) {
          if (dashboardInfo?.id !== undefined) {
            setDashboardInfoState({
              ...dashboardInfo,
              ...{
                breadcrumps_sections: [selectedLevel1.label],
                custom_title: selectedLevel2?.label || "",
              },
            });
          }
        } else {
          setInitialData();
        }
      } else {
        setInitialData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLevel1, selectedLevel2, dashboardInfo]);

  useEffect(() => {
    setInitialData();
    setSelectedValue(yearList[0]);
    setSelectedItem(yearList[0].toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={{ px: 3 }}>
        {hideBreadcrumbs !== true && (
          <Breadcrumbs dashboardInfo={dashboardInfoState} />
        )}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          my={0}
          mx={0}
          p={0}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            mt={1}
            mb={0}
            mx={0}
            p={0}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "24px",
                fontWeight: 500,
                color: theme.palette.text.primary,
              }}
            >
              {dashboardInfoState?.custom_title
                ? dashboardInfoState?.custom_title
                : dashboardInfo?.title
                ? dashboardInfo.title
                : `Loading...`}
            </Typography>

            {hideSectionMenu !== true && (
              <IconButton
                color="primary"
                aria-label="Section Dashboards"
                onClick={handleMenuOpen}
              >
                <ArrowDropDownCircleIcon />
              </IconButton>
            )}

            {hideMonthWiseToggle !== true && dashboardInfo?.id && (
              <Stack marginLeft={"52px"}>
                <ToggleSwitch handleToggle={setMonthToggle} />
              </Stack>
            )}
          </Stack>
          {hidePreviousYearsMenu !== true && dashboardInfo?.id && (
            <Stack direction="row" alignItems="center" mr={26}>
              <Stack
                direction="row"
                alignItems="center"
                marginRight={monthToggle ? "44px" : ""}
              >
                <Typography
                  color={"#343A40"}
                  marginRight={"8px"}
                  fontWeight={400}
                  fontSize={"12px"}
                >
                  Type of Report
                </Typography>
                <MultiLevelDropdown
                  placeholder="Overview"
                  selectedValue={selectedDropdownValue}
                  setSelectedValue={setSelectedDropdownValue}
                  disabled={!monthToggle}
                  error={null}
                  selectedLevel1={selectedLevel1}
                  setSelectedLevel1={setSelectedLevel1}
                  setSelectedLevel2={setSelectedLevel2}
                  dashboardId={dashboardInfo?.id}
                />
              </Stack>
              {monthToggle && (
                <Stack direction="row" alignItems="center">
                  <Typography
                    color="text.primary"
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      marginRight: "12px",
                      color: "#343a40",
                    }}
                  >
                    Previous years to show
                  </Typography>
                  <Select
                    value={selectedValue}
                    onChange={handleChange}
                    IconComponent={ExpandMoreIcon}
                    sx={{
                      height: "40px",
                      marginLeft: "auto",
                      svg: {
                        color: "#343A40 !important",
                      },
                      "& .css-da6wn1-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root":
                        {
                          fontSize: "14px",
                          fontWeight: "400",
                          border: "1px solid #DADADA",
                          borderRadius: "4px",
                          padding: "8px 12px",
                        },
                    }}
                  >
                    {yearList.map((option) => (
                      <MenuItem
                        key={option}
                        value={option}
                        style={{
                          color: "#343A40",
                          fontSize: "12px",
                        }}
                        selected={selectedValue === option}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      </Box>

      {hideSectionMenu !== true && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          id={"dashboards-list"}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={isMenuOpen}
          onClose={handleMenuClose}
          sx={{
            borderRadius: "4px",
            ".MuiPaper-root": {
              width: "330px",
              borderRadius: "4px",
            },
            ".MuiMenu-list": {
              m: 0,
              p: 0,
              borderRadius: "4px",
            },
          }}
        >
          {dashboardInfo && (
            <Section
              dashboards={sectionDashboards}
              section={dashboardInfo.section}
            />
          )}
        </Menu>
      )}
    </>
  );
}
