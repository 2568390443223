import * as React from "react";
import { Drawer } from "@mui/material";
import { SavedQuery } from "../utils/types";
import DataVisualizer from "./DataVisualizer";

export interface IVisualizeDrawerProps {
  open: boolean;
  handleClose: () => void;
  savedQuery: SavedQuery;
}

export default function VisualizeDrawer({
  open,
  handleClose,
  savedQuery,
}: IVisualizeDrawerProps) {
  return (
    <React.Fragment>
      <Drawer anchor={"right"} open={open} onClose={handleClose}>
        <DataVisualizer handleClose={handleClose} savedQuery={savedQuery} />
      </Drawer>
    </React.Fragment>
  );
}
