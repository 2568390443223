import * as React from "react";
import { useTheme, styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar";
import { Button, Stack } from "@mui/material";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { useData } from "../contexts/DataContext";
import { useGoogleAuth } from "../contexts/GoogleAuthContext";
import { baseUrl } from "../config";
import axiosConfig from "../utils/axiosConfig";

function DataboardIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        id="Rectangle 65"
        d="M0 5C0 4.44772 0.447715 4 1 4H3C3.55228 4 4 4.44772 4 5V18H0V5Z"
        fill="#E6292E"
      />
      <path
        id="Rectangle 67"
        d="M12 10C12 9.44772 12.4477 9 13 9H15C15.5523 9 16 9.44772 16 10V18H12V10Z"
        fill="#E6292E"
      />
      <path
        id="Rectangle 66"
        d="M6 1C6 0.447716 6.44772 0 7 0H9C9.55228 0 10 0.447715 10 1V18H6V1Z"
        fill="#E6292E"
      />
    </SvgIcon>
  );
}

function SearchIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        d="M19.4073 17.7527L14.9969 13.3422C16.0587 11.9286 16.6319 10.208 16.63 8.44C16.63 3.92406 12.9559 0.25 8.44 0.25C3.92406 0.25 0.25 3.92406 0.25 8.44C0.25 12.9559 3.92406 16.63 8.44 16.63C10.208 16.6319 11.9286 16.0587 13.3422 14.9969L17.7527 19.4073C17.9759 19.6069 18.2671 19.7135 18.5664 19.7051C18.8658 19.6967 19.1506 19.574 19.3623 19.3623C19.574 19.1506 19.6967 18.8658 19.7051 18.5664C19.7135 18.2671 19.6069 17.9759 19.4073 17.7527ZM2.59 8.44C2.59 7.28298 2.9331 6.15194 3.5759 5.18991C4.21871 4.22789 5.13235 3.47808 6.2013 3.03531C7.27025 2.59253 8.44649 2.47668 9.58128 2.70241C10.7161 2.92813 11.7584 3.48529 12.5766 4.30343C13.3947 5.12156 13.9519 6.16393 14.1776 7.29872C14.4033 8.43351 14.2875 9.60975 13.8447 10.6787C13.4019 11.7476 12.6521 12.6613 11.6901 13.3041C10.7281 13.9469 9.59702 14.29 8.44 14.29C6.88906 14.2881 5.40217 13.6712 4.30548 12.5745C3.2088 11.4778 2.59186 9.99095 2.59 8.44Z"
        fill="black"
      />
    </SvgIcon>
  );
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function PrimarySearchAppBar() {
  const theme = useTheme();
  const location = useLocation();
  const { serachInput, handleSearchInputChange } = useData();
  const isAtHome = location.pathname === "/";

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const { user, clearUser } = useGoogleAuth();
  const navigate = useNavigate();

  // User logout action
  const logout = async () => {
    try {
      // logout API
      const response: AxiosResponse = await axiosConfig.post(
        `${baseUrl}/logout/`
      );

      // Check if the response status is OK (200)
      if (response.status === 200) {
        console.log(" User logged out");
      } else {
        throw new Error("Failed to logout");
      }
    } catch (error) {
      console.error(error);
      throw new Error("An error occurred while logging out ");
    } finally {
      clearUser();
      navigate("/login");
    }
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      sx={{ mt: "45px" }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {user && <MenuItem onClick={logout}>Logout</MenuItem>}
    </Menu>
  );

  const LoginProfile = () => (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={0}
    >
      <Avatar alt="James Hall" src={user?.profile_pic || ""} />
      <Stack direction="column" spacing={0} ml={2}>
        <Typography
          variant="body2"
          textAlign="left"
          sx={{ fontSize: "16px", fontWeight: 400 }}
        >
          {user?.name || ""}
        </Typography>
      </Stack>
      <IconButton
        size="large"
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        color="inherit"
        disableRipple
        disableTouchRipple
      >
        <ArrowDropDownIcon />
      </IconButton>
    </Stack>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        {user && <LoginProfile />}
      </MenuItem>
    </Menu>
  );

  const goToHome = (): void => {
    navigate("/");
  };

  return (
    <Box sx={{ padding: theme.spacing(6) }}>
      <AppBar
        position="fixed"
        sx={{
          color: theme.palette.text.primary,
          borderRadius: "5px",
          boxShadow: "none",
          backgroundColor: "white",
          height: "72px",
          borderBottom: `2px solid ${theme.palette.primary.main}`,
          padding: "4px",
        }}
      >
        <Toolbar sx={{ height: "100%", px: 2 }} disableGutters>
          <Button
            sx={{ color: "inherit", textTransform: "none", p: 0 }}
            onClick={() => goToHome()}
          >
            <DataboardIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                display: {
                  xs: "none",
                  sm: "block",
                  fontWeight: "600",
                  fontSize: "22px",
                },
              }}
            >
              QB <span style={{ color: theme.palette.primary.main }}>D</span>
              ataboard
            </Typography>
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          <Search
            sx={{
              height: "48px",
              margin: "10px",
              border: "1px solid rgba(0, 0, 0, 0.2)",
              borderRadius: "8px",
              display: "none",
              ...(isAtHome && { display: "block" }),
            }}
          >
            <SearchIconWrapper sx={{ ml: 1, height: "100%" }}>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search for a dashboard or a section"
              inputProps={{ "aria-label": "search" }}
              fullWidth
              value={serachInput}
              onChange={handleSearchInputChange}
              sx={{
                height: "100%",
                width: "100%",
                input: { width: "319px !important" },
                [theme.breakpoints.down("sm")]: {
                  width: "30vh",
                },
              }}
            />
          </Search>

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <div onClick={handleProfileMenuOpen}>
              <LoginProfile />
            </div>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
