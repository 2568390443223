const financialAnalysisStyle = {
  subTableText: {
    fontSize: "14px",
    fontWeight: "400",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  cursorDefault: {
    cursor: "auto",
  },
};

export default financialAnalysisStyle;
