import { Box, Chip, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { ActiveFilterSectionProps, AppliedFilter } from "../types";

const ActiveFilterSection: React.FC<ActiveFilterSectionProps> = ({
  appliedFilter,
  clearAllFilters,
  clearFilter,
  hideDateRange,
}) => {
  const filterKeys = (
    Object.keys(appliedFilter) as (keyof AppliedFilter)[]
  ).filter((key) => {
    const filterValue = appliedFilter[key];

    const isDateRangeFilter =
      key === "Date_Range" && hideDateRange && filterValue !== "-";
    const isYearFilter = key === "Year" && !hideDateRange;

    return (
      (isDateRangeFilter ||
        isYearFilter ||
        (key !== "Date_Range" && key !== "Year")) &&
      filterValue !== undefined &&
      (Array.isArray(filterValue) ? filterValue.length > 0 : !!filterValue)
    );
  });

  if (filterKeys.length === 0) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" rowGap={0.5} flexWrap="wrap">
      <Typography
        color="rgba(58, 53, 65, 0.7)"
        sx={{ fontSize: "12px", fontWeight: "400" }}
      >
        Active Filters:
      </Typography>
      <Box
        display="flex"
        flexWrap="wrap"
        gap={1}
        flexGrow={1}
        alignItems={"center"}
      >
        {filterKeys.map((key) => (
          <Chip
            variant="outlined"
            key={key}
            label={`${key.replace("_", " ")}: ${
              Array.isArray(appliedFilter[key])
                ? appliedFilter[key] + " "
                : appliedFilter[key]
            }`}
            onDelete={() => clearFilter(key)}
            sx={{
              maxWidth: "20vw",
              overflow: "hidden",
              border: "none",
            }}
            deleteIcon={
              <Box
                sx={{
                  backgroundColor: "#FF4C51",
                  borderRadius: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "12px",
                  height: "12px",
                  margin: 0,
                }}
              >
                <CloseIcon
                  sx={{
                    color: "white",
                    width: "10px",
                    height: "10px",
                  }}
                />
              </Box>
            }
          />
        ))}
        <Typography
          variant="body2"
          color="#FF4C51"
          onClick={() => clearAllFilters(true)}
          sx={{
            cursor: "pointer",
            whiteSpace: "nowrap",
            alignItems: "center",
            fontSize: "12px",
            fontWeight: "400",
          }}
        >
          Clear All
        </Typography>
      </Box>
    </Box>
  );
};

export default ActiveFilterSection;
