import React, { useState } from "react";
import { Filter, Option } from "../utils/types";

interface DashboardFilterContextType {
  filters: Filter[];
  filterValues: FilterValues;
  lastUpdatedFilter: { id: string; value: any } | null;
  appliedFilterValues: FilterValues;
  updateFilterValue: (id: string, value: any) => void;
  resetFilters: () => void;
  applyFilters: () => void;
  isApplyBtnEnabled: () => boolean;
  isResetBtnEnabled: () => boolean;
}

// Create the DashboardFilter context
const DashboardFilterContext =
  React.createContext<DashboardFilterContextType | null>(null);

// Custom hook to access the DashboardFilter context
export const useDashboardFilter = () => {
  const context = React.useContext(DashboardFilterContext);
  if (!context) {
    throw new Error(
      "useDashboardFilter must be used within a DashboardFilterProvider"
    );
  }
  return context;
};

type FilterValues = { [key: string]: any };

interface IDashboardFilterProvider {
  children: any;
  filters: Filter[];
}

// DashboardFilterProvider component to wrap your application with
export const DashboardFilterProvider = ({
  children,
  filters,
}: IDashboardFilterProvider) => {
  const [filterValues, setFilterValues] = useState<FilterValues>(
    filters.reduce((acc: FilterValues, curr) => {
      acc[curr.id] = curr.value;
      return acc;
    }, {})
  );

  const [appliedFilterValues, setAppliedFilterValues] = useState<FilterValues>(
    filters.reduce((acc: FilterValues, curr) => {
      if (!curr.value) return acc;
      acc[curr.id] = curr.value;
      return acc;
    }, {})
  );

  const [lastUpdatedFilter, setLastUpdatedFilter] = useState<{
    id: string;
    value: any;
  } | null>(null);

  function updateFilterValue(id: string, value: any) {
    setFilterValues((prevFilterValues) => {
      return { ...prevFilterValues, [id]: value };
    });
    setLastUpdatedFilter({ id, value });
  }

  function resetFilters() {
    setFilterValues(
      filters.reduce((acc: FilterValues, curr) => {
        acc[curr.id] = curr.value;
        return acc;
      }, {})
    );

    if (
      Object.entries(appliedFilterValues).some(([filterId, value]) => {
        const filter = filters.find((_fltr) => _fltr.id === filterId)!;
        if (filter.type === "values") return value.length;
        return value;
      })
    ) {
      setAppliedFilterValues(
        filters.reduce((acc: FilterValues, curr) => {
          if (!curr.value) return acc;
          acc[curr.id] = curr.value;
          return acc;
        }, {})
      );
    }
  }

  function applyFilters() {
    setAppliedFilterValues(filterValues);
  }

  function isApplyBtnEnabled() {
    //return true if there is any filter that has value,
    //also check if its different from applied filter value
    return Object.entries(filterValues).some(([filterId, value]) => {
      const filter = filters.find((_fltr) => _fltr.id === filterId)!;
      if (filter.type === "values") {
        if (appliedFilterValues[filterId]) {
          const arr1: Option[] = value;
          const arr2: Option[] = appliedFilterValues[filterId];

          if (arr1.length !== arr2.length) return true;
          if (arr1.length === 0) return false;

          const sortedArr1 = [...arr1].sort();
          const sortedArr2 = [...arr2].sort();

          const hasSomeDifrntValue = sortedArr1.some(
            (value, index) => value !== sortedArr2[index]
          );

          if (hasSomeDifrntValue) return true;
          return false;
        }
        return false;
      }
      if (appliedFilterValues[filterId] !== undefined)
        return appliedFilterValues[filterId] !== value;
      if (value) return true;
      return false;
    });
  }

  function isResetBtnEnabled() {
    //return true if there is any filter value that is not the default value
    return Object.entries(filterValues).some(([filterId, value]) => {
      const filter = filters.find((_fltr) => _fltr.id === filterId)!;
      if (filter.type === "values") {
        const arr1: Option[] = value;
        const arr2: Option[] = filter.value;

        if (arr1.length !== arr2.length) return true;
        if (arr1.length === 0) return false;

        const sortedArr1 = [...arr1].sort();
        const sortedArr2 = [...arr2].sort();

        const hasSomeDifrntValue = sortedArr1.some(
          (value, index) => value !== sortedArr2[index]
        );

        if (hasSomeDifrntValue) return true;
        return false;
      }
      return value !== filter.value;
    });
  }

  return (
    <DashboardFilterContext.Provider
      value={{
        filters,
        filterValues,
        lastUpdatedFilter,
        appliedFilterValues,
        updateFilterValue,
        resetFilters,
        applyFilters,
        isApplyBtnEnabled,
        isResetBtnEnabled,
      }}
    >
      {children}
    </DashboardFilterContext.Provider>
  );
};
