import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import "../assets/css/Home.css";

import { useData } from "../contexts/DataContext";

import Sections from "../components/Sections";
import PrimaryDashboard from "../components/PrimaryDashboard";

import { Box, Grid, Typography, useTheme } from "@mui/material";

import AppBar from "../layouts/AppBar";
import Footer from "../layouts/Footer";

const Home: React.FC = () => {
  const theme = useTheme();

  const { dashboardList, primaryDashboardList, loading } = useData();

  const [sections, setSections] = useState<any>({});
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    loading ? setOpen(true) : setOpen(false);
  }, [loading]); // Only re-run the effect if loading changes

  useEffect(() => {
    const ss: any = {};
    dashboardList.map((dasahboard) => {
      if (!ss[dasahboard.section]) ss[dasahboard.section] = [];
      ss[dasahboard.section].push(dasahboard);
      return true;
    });
    setSections(ss);
  }, [dashboardList]);

  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <AppBar />
      <Box sx={{ mx: 2, px: 2 }}>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: 300,
            color: theme.palette.text.primary,
          }}
        >
          {`Your list contains a total of ${
            dashboardList.length
          } dashboards organized into ${
            Object.keys(sections).length
          } categories`}
        </Typography>
      </Box>
      <Grid container spacing={2} padding={2} sx={{ flexGrow: 1 }}>
        {/*
          1. if current role has access to Summary dashboard
              -> show Primary home with Summary dashboard
          2. else if current role has only one dashboard
              -> show Primary home with that one dashboard
          3. else
              -> show Sections home
        */}
        {primaryDashboardList.length > 0 ? (
          <PrimaryDashboard dashboardInfo={primaryDashboardList[0]} />
        ) : dashboardList.length === 1 ? (
          <PrimaryDashboard dashboardInfo={dashboardList[0]} />
        ) : (
          <Sections sections={sections} />
        )}
      </Grid>
      <Loading open={loading || open} type="spinner" />
      <Footer />
    </Box>
  );
};

export default Home;
