import { useEffect, useState } from "react";
import { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import axiosConfig from "../utils/axiosConfig";

export interface UseFetchState<T> {
  data: T | null;
  loading: boolean;
  error: AxiosError | null;
}

const useFetch = <T>(
  options: AxiosRequestConfig<any> | null
): UseFetchState<T> => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    if (!options) return;

    const fetchData = async () => {
      setLoading(true);

      try {
        const response: AxiosResponse<any> = await axiosConfig(options);

        if (response.data?.status && response.data.status === "error") {
          throw new AxiosError(
            response.data.message || "Something went wrong!"
          );
        }

        setData(response.data);
      } catch (error) {
        console.error(error);
        setError(error as AxiosError);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      setLoading(false);
      setError(null);
      setData(null);
    };
  }, [options]);

  return { loading, error, data };
};

export default useFetch;
