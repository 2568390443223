import { createContext, useContext, useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";

import { baseUrl } from "../config";
import { isEmbeddedDashboard } from "../utils/helpers";
import { Role } from "../utils/roles";
import { UserDetails } from "../utils/types";
import { TokenResponse } from "@react-oauth/google";
import useLocalStorage from "../hooks/useLocalStorage";
import axiosConfig from "../utils/axiosConfig";

interface userLoginResponse {
  isLoggedIn: boolean;
  errorMessage?: string;
  hasPrimary?: boolean;
}

interface GoogleAuthContextType {
  user: UserDetails | null;
  accessToken: string | null;
  guestToken: string | null;
  userRoles: Role[];
  setToken: (accessToken: string) => void;
  clearUser: () => void;
  setUser: (user: UserDetails) => void;
  userLogin: (tokenResponse: TokenResponse) => Promise<userLoginResponse>;
}

const GoogleAuthContext = createContext<GoogleAuthContextType | null>(null);

// Custom hook to access the authentication context
export const useGoogleAuth = () => {
  const context = useContext(GoogleAuthContext);
  if (!context) {
    throw new Error(
      "useGoogleAuth must be used within a GoogleUserAuthProvider"
    );
  }
  return context;
};

export const GoogleUserAuthProvider = ({ children }: { children: any }) => {
  // initially check for localstorage data to set token and user states
  const [localUserData, setLocalUserData] = useLocalStorage<UserDetails | null>(
    "userDetails",
    null
  );
  const [, setLocalGuestToken] = useLocalStorage<string | null>(
    "guestToken",
    null
  );

  const [guestToken, setGuestToken] = useState<string | null>(null);

  const [accessToken, setAccessToken] = useState(() =>
    localUserData ? localUserData.access_token : null
  );

  const setToken = (newAccessToken: string) => {
    setAccessToken(newAccessToken);
  };

  const clearUser = () => {
    setAccessToken(null);
    localStorage.clear();
  };

  const userLogin = async (tokenResponse: TokenResponse) => {
    try {
      // Define the request body
      const requestBody = {
        access_token: tokenResponse.access_token,
      };

      // Make a POST request to the login API
      const response: AxiosResponse = await axios({
        method: "post",
        url: `${baseUrl}/login/`,
        data: requestBody,
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Check if the response status is OK (200)
      if (response.status === 200 && response.data !== "Unauthorized") {
        // Handle successful login here, e.g., store authentication token, redirect, etc.
        console.log("Login successful");
        // set user token context
        setToken(response.data.access);

        const userDetails: UserDetails = {
          access_token: response.data.access,
          refresh_token: response.data.refresh,
          email: response.data.email,
          name: response.data.name,
          profile_pic: response.data.profile_image,
          id: response.data.id,
          roles: response.data.roles,
          has_primary: response.data.has_primary_dashboard,
        };
        // set user details to localstorage
        setLocalUserData(userDetails);
      } else {
        console.error("User not logged in");
        return {
          isLoggedIn: false,
          errorMessage: "Login failed. Please check your credentials.",
        };
      }

      // Reset form fields and error message
      return {
        isLoggedIn: true,
        hasPrimary: response.data.has_primary_dashboard,
      };
    } catch (error: any) {
      // Handle login error
      console.error("Login error:", error);
      // Not a registered user
      if (error.response.status === 403) {
        return {
          isLoggedIn: false,
          errorMessage: "Not a registered user. Please contact administrator.",
        };
      } else if (error.response.status === 401) {
        return {
          isLoggedIn: false,
          errorMessage: "Failed to fetch user info. Please try again.",
        };
      } else {
        // Display an error message to the user
        return {
          isLoggedIn: false,
          errorMessage: "Unexpected Error. Please contact administrator.",
        };
      }
    }
  };

  useEffect(() => {
    if (!localUserData || isEmbeddedDashboard()) return;

    const getUserData = async () => {
      const response: AxiosResponse = await axiosConfig({
        method: "get",
        url: `${baseUrl}/user-info/`,
      });

      if (response.status === 200) {
        setLocalUserData({ ...localUserData, ...response.data });
      }
    };

    getUserData();
  });

  useEffect(() => {
    if (!document.referrer) return;

    const parentOrigin = new URL(document.referrer).origin;
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== parentOrigin) return;

      if (event.data.type === "guestToken") {
        const _guestToken = event.data.guestToken;
        if (typeof _guestToken !== "string")
          throw new Error("guestToken should be a string");
        setLocalGuestToken(_guestToken);
        setGuestToken(_guestToken);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      setLocalGuestToken(null);
      setGuestToken(null);
      window.removeEventListener("message", handleMessage);
    };
    // eslint-disable-next-line
  }, []);

  const contextData = {
    user: localUserData,
    accessToken,
    guestToken,
    userRoles: localUserData?.roles || [],
    userLogin: userLogin,
    setToken: setToken,
    clearUser: clearUser,
    setUser: setLocalUserData,
  };
  return (
    <GoogleAuthContext.Provider value={contextData}>
      {children}
    </GoogleAuthContext.Provider>
  );
};
