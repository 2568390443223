import React, { useState } from "react";

import { ChartType, ChartBuilder, PreControls } from "../modules/charts/types";
import { ControlId, ControlInputId } from "../modules/charts/types";
import { ControlInputGroup, ControlGroupValue } from "../modules/charts/types";
import { Controls, ControlLabels } from "../modules/charts/types";

import { chartDefs } from "../modules/charts";
import { cleanSqlQuery, getWrapper } from "../modules/charts/common";

interface ChartBuilderContextType {
  chartType: ChartType;
  builder: ChartBuilder;
  controls: Controls;
  sql: string;
  changeChartType: (_chartType: ChartType) => void;
  addControlInputGroup: (controlId: ControlId) => void;
  addPreControlInputGroups: (preControls: PreControls) => void;
  removeControlInputGroup: (
    controlId: ControlId,
    inputGroupIdx: number
  ) => void;
  clearControlGroups: () => void;
  updateControlInputValue: (
    controlId: ControlId,
    inputGroupIdx: number,
    inputId: ControlInputId,
    value: string
  ) => void;
  updateControlGroupValue: (
    controlId: ControlId,
    inputGroupIdx: number,
    controlGroupValue: ControlGroupValue
  ) => void;
  hasRequiredValues: () => boolean;
  getLabels: () => ControlLabels;
  buildSql: (baseQuery: string, backend?: string) => void;
}

// Create the ChartBuilder context
const ChartBuilderContext = React.createContext<ChartBuilderContextType | null>(
  null
);

// Custom hook to access the ChartBuilder context
export const useChartBuilder = () => {
  const context = React.useContext(ChartBuilderContext);
  if (!context) {
    throw new Error(
      "useChartBuilder must be used within a ChartBuilderProvider"
    );
  }
  return context;
};

interface IChartBuilderProvider {
  children: any;
  defaultChartType: ChartType;
}

// ChartBuilderProvider component to wrap your application with
export const ChartBuilderProvider = ({
  children,
  defaultChartType,
}: IChartBuilderProvider) => {
  const [chartType, setChartType] = useState<ChartType>(defaultChartType);
  const [controls, setControls] = useState<Controls>(
    JSON.parse(JSON.stringify(chartDefs[chartType].controls))
  );
  const [sql, setSql] = useState<string>("");

  function changeChartType(_chartType: ChartType) {
    setChartType(_chartType);
    setControls((prevConrols) => {
      const newControls: Controls = JSON.parse(
        JSON.stringify(chartDefs[_chartType].controls)
      );
      Object.entries(prevConrols).forEach(([key, control]) => {
        if (key in newControls) newControls[key as ControlId] = control;
      });
      return newControls;
    });
  }

  function addControlInputGroup(controlId: ControlId) {
    setControls((prevControls) => {
      const newControls = { ...prevControls };
      const control = newControls[controlId]!;
      control.inputGroups = [
        ...control.inputGroups,
        JSON.parse(JSON.stringify(control.inputGroup)),
      ];
      return newControls;
    });
  }

  function addPreControlInputGroups(preControls: PreControls) {
    setControls((prevControls) => {
      const newControls: Controls = { ...prevControls };
      Object.entries(preControls).forEach(([_controlId, controlValues]) => {
        const controlId = _controlId as ControlId;
        if (!(controlId in newControls)) return;
        controlValues.forEach((controlValue: ControlGroupValue) => {
          const newInputGroup: ControlInputGroup = JSON.parse(
            JSON.stringify(newControls[controlId]!.inputGroup)
          );
          if ("column" in newInputGroup.inputs)
            newInputGroup.inputs.column.value = controlValue.column;
          if ("label" in newInputGroup.inputs)
            newInputGroup.inputs.label.value = controlValue.label;
          if (
            "aggregation" in newInputGroup.inputs &&
            "aggregation" in controlValue
          )
            newInputGroup.inputs.aggregation.value = controlValue.aggregation;

          if (
            newControls[controlId]!.maxGroups >
            newControls[controlId]!.inputGroups.length
          )
            newControls[controlId]!.inputGroups.push(newInputGroup);
        });
      });
      return newControls;
    });
  }

  function removeControlInputGroup(
    controlId: ControlId,
    inputGroupIdx: number
  ) {
    setControls((prevControls) => {
      const newControls = { ...prevControls };
      const control = newControls[controlId]!;
      control.inputGroups.splice(inputGroupIdx, 1);
      return newControls;
    });
  }

  function clearControlGroups() {
    setControls((prevControls) => {
      const newControls = { ...prevControls };
      Object.values(newControls).forEach((_control) => {
        newControls[_control.id]!.inputGroups = [];
      });
      return newControls;
    });
  }

  function updateControlInputValue(
    controlId: ControlId,
    inputGroupIdx: number,
    inputId: ControlInputId,
    value: string
  ) {
    setControls((prevControls) => {
      const newControls = { ...prevControls };
      const control = newControls[controlId]!;
      const inputGroup = control.inputGroups[inputGroupIdx];

      if (
        inputGroup.groupType === "column" &&
        (inputId === "column" || inputId === "label")
      ) {
        inputGroup.inputs[inputId].value = value;
      } else if (
        inputGroup.groupType === "metrics" &&
        (inputId === "column" ||
          inputId === "label" ||
          inputId === "aggregation")
      ) {
        inputGroup.inputs[inputId].value = value;
      } else if (
        inputGroup.groupType === "table-column" &&
        (inputId === "column" ||
          inputId === "label" ||
          inputId === "parentColumns" ||
          inputId === "width")
      ) {
        inputGroup.inputs[inputId].value = value;
      }

      return newControls;
    });
  }

  function updateControlGroupValue(
    controlId: ControlId,
    inputGroupIdx: number,
    controlGroupValue: any
  ) {
    setControls((prevControls) => {
      const newControls = { ...prevControls };
      const control = newControls[controlId]!;
      const inputGroup = control.inputGroups[inputGroupIdx];

      if (inputGroup.groupType === "column") {
        inputGroup.inputs.column.value = controlGroupValue.column;
        inputGroup.inputs.label.value = controlGroupValue.label;
      } else if (inputGroup.groupType === "metrics") {
        inputGroup.inputs.column.value = controlGroupValue.column;
        inputGroup.inputs.label.value = controlGroupValue.label;
        inputGroup.inputs.aggregation.value = controlGroupValue.aggregation;
      } else if (inputGroup.groupType === "table-column") {
        inputGroup.inputs.column.value = controlGroupValue.column;
        inputGroup.inputs.label.value = controlGroupValue.label;
        inputGroup.inputs.parentColumns.value = controlGroupValue.parentColumns;
        inputGroup.inputs.width.value = controlGroupValue.width;
      }

      return newControls;
    });
  }

  function hasRequiredValues() {
    const hasMinRequiredControls = Object.values(controls).every(
      (control) => control.required === false || control.inputGroups.length > 0
    );
    const hasRequiredInputs = Object.values(controls).every((control) =>
      control.inputGroups.every((inputGroup) =>
        Object.values(inputGroup.inputs).every(
          (input) => !input.required || input.value !== ""
        )
      )
    );

    return hasMinRequiredControls && hasRequiredInputs;
  }

  function getLabels() {
    let xAxisLabel: string | null = null;
    const seriesLabels: string[] = [];
    const metricsLabels: string[] = [];

    Object.values(controls).forEach((control) =>
      control.inputGroups.forEach((inputGroup, inputGroupIdx) => {
        const label = inputGroup.inputs.label.value;

        if (control.id === "x-axis" && inputGroupIdx === 0) xAxisLabel = label;
        else if (control.id === "metrics") metricsLabels.push(label);
        else seriesLabels.push(label);
      })
    );

    return { xAxisLabel, seriesLabels, metricsLabels };
  }

  function buildSql(baseQuery: string, backend: string = "") {
    const w = getWrapper(backend);
    const selects: string[] = [];
    const groupBy: string[] = [];
    const orderBy: string[] = [];

    Object.values(controls).forEach((control) =>
      control.inputGroups.forEach((inputGroup) => {
        const temp: any = { aggregation: null, column: "", label: "" };
        Object.values(inputGroup.inputs).forEach(
          (input) => (temp[input.id] = input.value)
        );

        if (inputGroup.addTo.includes("SELECT"))
          selects.push(
            inputGroup.groupType === "metrics" && temp.aggregation
              ? `${temp.aggregation}(${w}${temp.column}${w}) AS ${w}${temp.label}${w}`
              : inputGroup.groupType === "table-column"
              ? `${w}${temp.column}${w} AS ${w}${temp.column}${w}`
              : `${w}${temp.column}${w} AS ${w}${temp.label}${w}`
          );

        if (inputGroup.addTo.includes("GROUP-BY"))
          groupBy.push(`${w}${temp.label}${w}`);

        if (inputGroup.addTo.includes("ORDER-BY"))
          orderBy.push(`${w}${temp.column}${w}`);
      })
    );

    const _sql: string = `
        SELECT ${selects.join(", ")}
        FROM (${cleanSqlQuery(baseQuery)}) virtual_table
        ${groupBy.length > 0 ? `GROUP BY ${groupBy.join(", ")}` : ``}
        ${orderBy.length > 0 ? `ORDER BY ${orderBy.join(", ")}` : ``}
    `;
    setSql(_sql);
  }

  return (
    <ChartBuilderContext.Provider
      value={{
        chartType,
        builder: chartDefs[chartType].builder,
        controls,
        sql,
        changeChartType,
        addControlInputGroup,
        addPreControlInputGroups,
        removeControlInputGroup,
        clearControlGroups,
        updateControlInputValue,
        updateControlGroupValue,
        hasRequiredValues,
        getLabels,
        buildSql,
      }}
    >
      {children}
    </ChartBuilderContext.Provider>
  );
};
