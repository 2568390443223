//hooks
import { useDashboardFilter } from "../contexts/DashboardFilterContext";
import { useToggle } from "../hooks";
import { useTheme } from "@mui/material";

//types
import { CustomChartProps } from "../modules/charts/types";
import { DashboardControls } from "../utils/types";

//components
import KeyboardTab from "@mui/icons-material/KeyboardTab";
import DashboardFilterField from "./DashboardFilterField";

//ui components
import { Box, Stack, Typography } from "@mui/material";
import { Button, IconButton } from "@mui/material";
import { FilterListRounded } from "@mui/icons-material";

export interface IDashboardFiltesProps {
  charts: CustomChartProps[];
  dashboardId: number;
  dashboardControls: DashboardControls;
}

export default function DashboardFiltes({
  charts,
  dashboardId,
  dashboardControls,
}: IDashboardFiltesProps) {
  const theme = useTheme();
  const { open, handleClose, handleOpen } = useToggle(false);
  const { filters, resetFilters, applyFilters } = useDashboardFilter();
  const { isApplyBtnEnabled, isResetBtnEnabled } = useDashboardFilter();

  function handleFilterSubmit(e: any) {
    e.preventDefault();
    applyFilters();
  }

  return (
    <Box p={1} width={open ? "300px" : "60px"} sx={{ transition: "all 0.3s" }}>
      <Box
        height="100%"
        width="100%"
        sx={{
          borderRadius: "5px",
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Stack
          pt={2}
          px={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {open && <Typography>{"Filters"}</Typography>}
          <IconButton onClick={open ? handleClose : handleOpen} sx={{ p: 0 }}>
            <KeyboardTab
              sx={{
                transform: `rotate(${open ? 180 : 0}deg)`,
                transition: "all 0.3s",
              }}
              color={open ? "inherit" : "primary"}
            />
          </IconButton>
        </Stack>
        {!open && (
          <Stack pt={2} px={2}>
            <IconButton onClick={handleOpen} sx={{ p: 0 }}>
              <FilterListRounded color={"primary"} />
            </IconButton>
          </Stack>
        )}
        <form onSubmit={handleFilterSubmit} hidden={!open}>
          <Stack p={2} spacing={2} direction="column">
            {filters.map((filter) => (
              <DashboardFilterField
                key={filter.id}
                filter={filter}
                charts={charts}
                dashboardId={dashboardId}
                dashboardControls={dashboardControls}
              />
            ))}
            <Stack
              pt={1}
              spacing={2}
              direction="row"
              justifyContent="space-between"
            >
              <Button
                type="button"
                size="small"
                variant="outlined"
                onClick={resetFilters}
                disabled={!isResetBtnEnabled()}
              >
                Reset
              </Button>
              <Button
                type="submit"
                size="small"
                variant="contained"
                fullWidth
                disabled={!isApplyBtnEnabled()}
              >
                Apply
              </Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Box>
  );
}
