import "../assets/css/dnd.css";
import RGL from "react-grid-layout";

//hooks
import { useState, useMemo } from "react";
import { useTheme } from "@mui/material";
import { useDashboardFilter } from "../contexts/DashboardFilterContext";
import { useData } from "../contexts/DataContext";

//providers
import { ChartBuilderProvider } from "../contexts/ChartBuilderContext";
import { WidthProvider } from "react-grid-layout";

//types
import { Layout } from "react-grid-layout";
import { SxProps, Theme } from "@mui/material";

//utils
import { customDashboards } from "../dashboards";
import { isEmbeddedDashboard } from "../utils/helpers";

//components
import DashboardFiltes from "./DashboardFilters";
import CustomChart from "./CustomChart";
import ExploreDataMenu from "./ExploreDataMenu";

//ui components
import { Box, Stack, Typography } from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DashboardControls } from "../utils/types";

function getChartWrapperStyle(theme: Theme) {
  const sx: SxProps<Theme> = {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    userSelect: "none",
  };
  return sx;
}

const ReactGridLayout = WidthProvider(RGL);

export interface ICustomDashboardProps {
  id: number;
  hideTitle?: boolean;
  hideExploreData?: boolean;
  hideFilter?: boolean;
}

export default function CustomDashboard({
  id,
  hideTitle = false,
  hideExploreData = false,
  hideFilter = false,
}: ICustomDashboardProps) {
  const theme = useTheme();

  const { dashboardList } = useData();
  const { filters } = useDashboardFilter();
  const { title, charts, controls } = customDashboards[id];

  const [chartGrids, setChartGrids] = useState(charts);

  const [dashboardControls, setDashboardControls] = useState<any>(
    controls || {}
  );

  function handleLayoutChange(layout: Layout[]) {
    setChartGrids((prevChartGrids) => {
      return prevChartGrids.map((chartGrid) => {
        chartGrid.layout = layout.find(
          (gridLayout) => gridLayout.i === chartGrid.layoutId
        )!;
        return chartGrid;
      });
    });
  }

  function handleControlChange(id: string, value: any) {
    setDashboardControls((prevControls: any) => {
      const newControls = { ...prevControls };
      if (id in newControls) newControls[id].value = value;
      return newControls;
    });
  }

  const dashboardInfo = useMemo(() => {
    return dashboardList.find((_db) => _db.id === id);
  }, [dashboardList, id]);

  return (
    <Stack direction="row" px={2} py={1} spacing={1}>
      {!hideFilter && filters.length > 0 && (
        <DashboardFiltes
          charts={charts}
          dashboardId={id}
          dashboardControls={dashboardControls}
        />
      )}
      <Box width="100%">
        {!hideTitle && (
          <Typography p={1} fontSize={24} fontWeight={500}>
            {title}
          </Typography>
        )}

        <Box
          sx={{
            position: "absolute",
            top: isEmbeddedDashboard() ? theme.spacing(2) : theme.spacing(14),
            right: theme.spacing(4),
          }}
        >
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={4}
          >
            {Object.values(dashboardControls as DashboardControls)
              .filter((dc) => dc !== undefined)
              .map((control) => {
                if (control.id === "datasetSwitchControl") {
                  return (
                    <ToggleButtonGroup
                      key={control.id}
                      color="primary"
                      size="small"
                      value={control.value}
                      exclusive
                      onChange={(e: any, value: string) =>
                        handleControlChange(control.id, value)
                      }
                      aria-label="Platform"
                    >
                      {control.switches.map((sth, idx) => (
                        <ToggleButton key={idx} value={sth.value}>
                          {sth.label}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  );
                }
                return false;
              })}

            {!hideExploreData &&
              dashboardInfo &&
              dashboardInfo.charts &&
              dashboardInfo.charts.length > 0 && (
                <ExploreDataMenu dashboardInfo={dashboardInfo} />
              )}
          </Stack>
        </Box>

        <ReactGridLayout
          className="layout"
          cols={12}
          onLayoutChange={handleLayoutChange}
          draggableHandle=".grid-drag-btn"
        >
          {chartGrids.map((chartGrid) => (
            <Box
              key={chartGrid.layout.i}
              data-grid={chartGrid.layout}
              sx={getChartWrapperStyle(theme)}
            >
              <ChartBuilderProvider defaultChartType={chartGrid.chartType}>
                <CustomChart
                  chartProps={{ ...chartGrid }}
                  dashboardId={id}
                  dashboardControls={dashboardControls}
                />
              </ChartBuilderProvider>
            </Box>
          ))}
        </ReactGridLayout>
      </Box>
    </Stack>
  );
}
