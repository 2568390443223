import { Menu, Button, Divider, Box } from "@mui/material";
import { ListItemText, MenuItem, Stack } from "@mui/material";
import { Link } from "react-router-dom";

import { MouseEvent } from "react";
import { DashboardInfo } from "../utils/types";

import { useState } from "react";
import { useTheme } from "@mui/material";
import { useGoogleAuth } from "../contexts/GoogleAuthContext";

import { hasRequiredRole, Role } from "../utils/roles";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

const { ROLE_DIRECTORS, ROLE_ACCOUNTS } = Role;

export interface IExploreDataMenuProps {
  dashboardInfo: DashboardInfo;
}

export default function ExploreDataMenu({
  dashboardInfo,
}: IExploreDataMenuProps) {
  const theme = useTheme();
  const { userRoles } = useGoogleAuth();

  const [anchorElSqlLab, setAnchorElSqlLab] = useState<null | HTMLElement>(
    null
  );

  const isSqlLabMenuOpen = Boolean(anchorElSqlLab);

  const handleSqlLabMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorElSqlLab(event.currentTarget);
  };

  const handleSqlLabMenuClose = () => {
    setAnchorElSqlLab(null);
  };

  return (
    <Box>
      {hasRequiredRole(userRoles, [ROLE_DIRECTORS, ROLE_ACCOUNTS]) &&
        dashboardInfo?.charts &&
        dashboardInfo.charts.length > 0 && (
          <Button
            variant="outlined"
            onClick={handleSqlLabMenuOpen}
            endIcon={<KeyboardArrowDownRoundedIcon />}
            color="primary"
          >
            Explore Data
          </Button>
        )}

      <Menu
        anchorEl={anchorElSqlLab}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        id={"charts-list"}
        keepMounted
        disableScrollLock
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isSqlLabMenuOpen}
        onClose={handleSqlLabMenuClose}
        sx={{
          borderRadius: "4px",
          ".MuiPaper-root": {
            borderRadius: "4px",
          },
          ".MuiMenu-list": {
            p: 0,
          },
        }}
      >
        {dashboardInfo?.charts &&
          dashboardInfo.charts.map((_chart, idx) => (
            <MenuItem key={idx} onClick={handleSqlLabMenuClose} sx={{ py: 0 }}>
              <Link
                target="_blank"
                to={{
                  pathname: "/sqllab",
                  search: `dashboardId=${dashboardInfo.id}&chartId=${_chart.id}`,
                }}
                style={{
                  textDecoration: "none",
                  color: theme.palette.text.primary,
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width={100}
                  sx={{
                    py: 0,
                    height: "44px",
                    width: "100%",
                    minWidth: "200px",
                  }}
                >
                  <ListItemText sx={{ fontSize: "14px" }}>
                    {_chart.name}
                  </ListItemText>
                  <OpenInNewIcon sx={{ ml: 2, fontSize: "14px" }} />
                </Stack>
              </Link>
              <Divider />
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
}
