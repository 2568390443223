import * as React from "react";
import {
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import ControlInputGroup from "./ControlInputGroup";
import { useChartBuilder } from "../contexts/ChartBuilderContext";
import { ControlId, IMenuItem } from "../modules/charts/types";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

export interface IChartControlProps {
  colItems: IMenuItem[];
  controlId: ControlId;
  addControlInputGroup: (controlId: ControlId) => void;
}

export default function ChartControl({
  colItems,
  controlId,
  addControlInputGroup,
}: IChartControlProps) {
  const theme = useTheme();
  const { controls } = useChartBuilder();

  const control = controls[controlId]!;

  function handleAdd() {
    addControlInputGroup(controlId);
  }

  return (
    <Stack direction="column" width={"100%"}>
      <Typography fontSize={14}>{control.title}</Typography>
      <Stack
        direction="column"
        spacing={0.5}
        padding={1}
        sx={{
          borderRadius: "6px",
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        {control.inputGroups.map((inputGroup, inputGroupIdx) => (
          <ControlInputGroup
            key={inputGroupIdx}
            colItems={colItems}
            controlId={controlId}
            inputGroupIdx={inputGroupIdx}
          />
        ))}
        {control.inputGroups.length < control.maxGroups && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width={"100%"}
            py={0.5}
            sx={{
              borderRadius: "4px",
              border: `1px solid ${theme.palette.divider}`,
              borderStyle: "dashed",
              cursor: "pointer",
              ":hover": {
                backgroundColor: theme.palette.grey[100],
              },
            }}
            onClick={handleAdd}
          >
            <Stack direction="row" alignItems="center">
              <IconButton size="small" sx={{ py: 0, px: 0.5 }}>
                <AddRoundedIcon />
              </IconButton>
              <Divider orientation="vertical" flexItem />
              <Typography fontSize={14} noWrap ml={1}>
                {"Click here to add"}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
