import * as React from "react";
import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import QbLogo from "../assets/img/qb-logo.png";

export default function SimpleBottomNavigation() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        height: "60px",
        position: "relative",
        bottom: 0,
        backgroundColor: theme.palette.background.default,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        boxShadow: "0px 4px 24px #0000001a",
      }}
    >
      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent="center"
        spacing={1}
      >
        <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: 400 }}>
          Powered By
        </Typography>

        <img
          src={QbLogo}
          alt="QBurst Logo"
          style={{
            height: "24px",
            // left: 0,
            // objectFit: "cover",
            // position: "fixed",
            // top: 0,
            // width: "56px",
          }}
        ></img>
      </Stack>
    </Box>
  );
}
