import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardHeader, IconButton, Stack, useTheme } from "@mui/material";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { DashboardInfo } from "../utils/types";

import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";

import { customDashboards } from "../dashboards";

dayjs.extend(isToday);
dayjs.extend(isYesterday);

interface SectionProps {
  section: string;
  dashboards: DashboardInfo[];
}

const Section: React.FC<SectionProps> = ({ section, dashboards }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const itemsCount = 3;
  const maxItemsCount = 3;

  const [offset, setOffset] = React.useState(0);

  const isFirstPage = offset === 0;
  const isLastPage = offset + itemsCount > dashboards.length;

  const handlePrevious = () => {
    setOffset(offset - itemsCount);
  };

  const handleNext = () => {
    setOffset(offset + itemsCount);
  };

  const goToDashboard = (id: number) => {
    if (customDashboards[id]) {
      window.location.href = `/dashboard/${id}`;
    } else {
      navigate(`/dashboard/${id}`);
    }
  };

  const PreviousIcon = (props: SvgIconProps) => {
    return (
      <SvgIcon {...props}>
        <rect
          x="0.5"
          y="0.5"
          width="19"
          height="19"
          rx="5.5"
          fill={isFirstPage ? "#EEEEEE" : "#FEF5F6"}
          stroke={isFirstPage ? "#D8D8D8" : "#FAD3D5"}
        />
        <path
          d="M11.9933 5.06409C12.0948 5.1064 12.187 5.16839 12.2645 5.2465C12.3426 5.32397 12.4046 5.41614 12.4469 5.51768C12.4892 5.61923 12.511 5.72816 12.511 5.83817C12.511 5.94818 12.4892 6.0571 12.4469 6.15865C12.4046 6.2602 12.3426 6.35236 12.2645 6.42983L8.68113 10.0048L12.2645 13.5798C12.3426 13.6573 12.4046 13.7495 12.4469 13.851C12.4892 13.9526 12.511 14.0615 12.511 14.1715C12.511 14.2815 12.4892 14.3904 12.4469 14.492C12.4046 14.5935 12.3426 14.6857 12.2645 14.7632C12.187 14.8413 12.0948 14.9033 11.9933 14.9456C11.8917 14.9879 11.7828 15.0097 11.6728 15.0097C11.5628 15.0097 11.4539 14.9879 11.3523 14.9456C11.2508 14.9033 11.1586 14.8413 11.0811 14.7632L6.91447 10.5965C6.83636 10.519 6.77437 10.4269 6.73206 10.3253C6.68975 10.2238 6.66797 10.1148 6.66797 10.0048C6.66797 9.89482 6.68975 9.7859 6.73206 9.68435C6.77437 9.5828 6.83636 9.49063 6.91447 9.41316L11.0811 5.2465C11.1586 5.16839 11.2508 5.1064 11.3523 5.06409C11.4539 5.02178 11.5628 5 11.6728 5C11.7828 5 11.8917 5.02178 11.9933 5.06409Z"
          fill={isFirstPage ? "#C3C4C6" : "#E6292E"}
        />
      </SvgIcon>
    );
  };

  const NextIcon = (props: SvgIconProps) => {
    return (
      <SvgIcon {...props}>
        <rect
          x="0.5"
          y="0.5"
          width="19"
          height="19"
          rx="5.5"
          fill={isLastPage ? "#EEEEEE" : "#FEF5F6"}
          stroke={isLastPage ? "#D8D8D8" : "#FAD3D5"}
        />
        <path
          d="M13.0965 9.4136L8.92983 5.24694C8.85213 5.16924 8.75989 5.10761 8.65837 5.06556C8.55685 5.0235 8.44805 5.00186 8.33817 5.00186C8.11625 5.00186 7.90342 5.09002 7.7465 5.24694C7.6688 5.32464 7.60717 5.41688 7.56512 5.5184C7.52307 5.61992 7.50142 5.72872 7.50142 5.8386C7.50142 6.06052 7.58958 6.27335 7.7465 6.43027L11.3298 10.0053L7.7465 13.5803C7.66839 13.6577 7.60639 13.7499 7.56409 13.8515C7.52178 13.953 7.5 14.0619 7.5 14.1719C7.5 14.2819 7.52178 14.3909 7.56409 14.4924C7.60639 14.594 7.66839 14.6861 7.7465 14.7636C7.82397 14.8417 7.91614 14.9037 8.01769 14.946C8.11924 14.9883 8.22816 15.0101 8.33817 15.0101C8.44818 15.0101 8.55709 14.9883 8.65864 14.946C8.76019 14.9037 8.85236 14.8417 8.92983 14.7636L13.0965 10.5969C13.1746 10.5195 13.2366 10.4273 13.2789 10.3258C13.3212 10.2242 13.343 10.1153 13.343 10.0053C13.343 9.89526 13.3212 9.78634 13.2789 9.68479C13.2366 9.58324 13.1746 9.49107 13.0965 9.4136Z"
          fill={isLastPage ? "#C3C4C6" : "#E6292E"}
        />
      </SvgIcon>
    );
  };

  return (
    <Card
      sx={{
        position: "relative",
        height: "380px",
        width: "100%",
        borderRadius: "8px",
        boxShadow: "none",
        border: `1px solid ${theme.palette.grey[200]}`,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      }}
    >
      <CardHeader
        title={section}
        subheader={`${dashboards.length} Dashboards`}
        sx={{
          py: 0,
          px: 2,
          height: "71px",
          background: "linear-gradient(0deg, #343A40 0%, #525456 100%)",
          ".MuiCardHeader-title": {
            fontSize: "18px",
            fontWeight: 600,
            color: "white",
          },
          ".MuiCardHeader-subheader": {
            fontSize: "16px",
            fontWeight: 400,
            color: "white",
          },
        }}
      ></CardHeader>
      <CardContent sx={{ p: 1.5 }}>
        <Stack direction="column" spacing={0.25}>
          {dashboards.map((dashboard, idx) => (
            <React.Fragment key={idx}>
              {(dashboards.length <= maxItemsCount ||
                (idx >= offset && idx < offset + itemsCount)) && (
                <Stack
                  direction="column"
                  justifyContent="center"
                  sx={{
                    height: "54px",
                    borderRadius: "8px",
                    backgroundColor: theme.palette.grey[50],
                    px: 2,
                    py: 1,
                    ":hover": {
                      backgroundColor: theme.palette.grey[200],
                    },
                  }}
                  onClick={() => goToDashboard(dashboard.id)}
                >
                  <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
                    {dashboard.title}
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={1.5}>
                    {dayjs(dashboard.updated_at).isToday() && (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 300,
                          my: 1,
                          color: theme.palette.text.secondary,
                        }}
                      >
                        {" Last updated today"}
                      </Typography>
                    )}

                    {dayjs(dashboard.updated_at).isYesterday() && (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 300,
                          my: 1,
                          color: theme.palette.text.secondary,
                        }}
                      >
                        {" Last updated yesterday"}
                      </Typography>
                    )}

                    {!dayjs(dashboard.updated_at).isToday() &&
                      !dayjs(dashboard.updated_at).isYesterday() && (
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 300,
                            my: 1,
                            color: theme.palette.text.secondary,
                          }}
                        >
                          {" Last updated on "}{" "}
                          {dayjs(dashboard.updated_at).format("DD MMM YYYY")}
                        </Typography>
                      )}
                  </Stack>
                </Stack>
              )}
            </React.Fragment>
          ))}
        </Stack>
      </CardContent>
      {dashboards.length > maxItemsCount && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            py: 1,
            px: 0,
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: "54px",
            borderTop: `1px solid ${theme.palette.grey[200]}`,
          }}
        >
          <IconButton
            onClick={handlePrevious}
            disabled={isFirstPage}
            disableRipple
            sx={{ p: 0 }}
          >
            <PreviousIcon width="100" height="100" />
          </IconButton>
          <Typography
            align="center"
            sx={{ width: "200px", pb: 0.5 }}
          >{`Showing ${offset + 1}-${
            offset + itemsCount > dashboards.length
              ? dashboards.length
              : offset + itemsCount
          } of ${dashboards.length}`}</Typography>
          <IconButton
            onClick={handleNext}
            disabled={isLastPage}
            disableRipple
            sx={{ p: 0 }}
          >
            <NextIcon width="100" height="100" />
          </IconButton>
        </Stack>
      )}
    </Card>
  );
};

export default Section;
