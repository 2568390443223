import Loader from "./Loader";
import { Backdrop, CircularProgress } from "@mui/material";

interface ILoading {
  open: boolean;
  type?: "spinner" | "qb-loader";
}

export default function Loading({
  open = false,
  type = "qb-loader",
}: ILoading) {
  return !open ? (
    <></>
  ) : (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme: any) => theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      {type === "spinner" ? <CircularProgress color="inherit" /> : <Loader />}
    </Backdrop>
  );
}
