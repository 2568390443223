import { CustomDashboardProps } from "../utils/types";
import {
  getDefaultValueWhereClause,
  handleExport,
} from "../utils/dashboard-utils";

export const dashboard: CustomDashboardProps = {
  title: "Project Wise Allocations",
  charts: [
    {
      id: "1",
      datasetId: "7",
      chartType: "table",
      title: "Project Wise Allocations",
      preControls: {
        "table-columns": [
          {
            column: "project_name",
            label: "Project",
            parentColumns: [],
            width: 200,
          },
          {
            column: "total_allocation",
            label: "Total Allocation",
            parentColumns: ["project_name"],
            width: 150,
          },
          {
            column: "grade",
            label: "Grade",
            parentColumns: ["project_name"],
            width: 150,
          },
          {
            column: "available_allocation",
            label: "Available Allocation",
            parentColumns: ["grade", "project_name"],
            width: 150,
          },
          {
            column: "shadow_allocation",
            label: "Shadow Allocation",
            parentColumns: ["grade", "project_name"],
            width: 150,
          },
          {
            column: "billable_allocation",
            label: "Billable Allocation",
            parentColumns: ["grade", "project_name"],
            width: 150,
          },
          {
            column: "internal_allocation",
            label: "Internal Allocation",
            parentColumns: ["grade", "project_name"],
            width: 150,
          },
          {
            column: "trainee_allocation",
            label: "Trainee Allocation",
            parentColumns: ["grade", "project_name"],
            width: 150,
          },
        ],
      },
      chartConfig: {
        sortType: "none",
      },
      layoutId: "1",
      layout: { x: 0, y: 0, w: 12, h: 5, i: "1" },
      chartActions: [
        { type: "export", label: "Export to Excel", handleClick: handleExport },
      ],
    },
  ],
  filters: [
    {
      id: "1",
      type: "value",
      name: "Customer",
      column: "customer_name",
      cascadedFilterIds: [],
      requiredFilterIds: [],
      appliesToChartIds: ["1"],
      value: null,
      defaultValue: null,
      required: false,
      options: null,
      getWhereClause: getDefaultValueWhereClause,
    },
    {
      id: "2",
      type: "value",
      name: "Project",
      column: "project_name",
      cascadedFilterIds: ["1"],
      requiredFilterIds: [],
      appliesToChartIds: ["1"],
      value: null,
      defaultValue: null,
      required: false,
      options: null,
      getWhereClause: getDefaultValueWhereClause,
    },
  ],
  controls: {
    datasetSwitchControl: {
      id: "datasetSwitchControl",
      switches: [
        { label: "Grouped", value: "7" },
        { label: "Detailed", value: "6" },
      ],
      value: "7",
    },
  },
};
