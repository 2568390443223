import { useState } from "react";

export type Toggle = {
  open: boolean;
  toggle: () => void;
  handleOpen: () => void;
  handleClose: () => void;
};

export default function useToggle(initialState = false): Toggle {
  const [open, setOpen] = useState<boolean>(initialState);

  const toggle = () => {
    setOpen(!open);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return { open, toggle, handleOpen, handleClose };
}
