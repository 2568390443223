import * as React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../assets/css/Dashboard.css";

export default function BasicBreadcrumbs({ dashboardInfo, sqlLabInfo }: any) {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ mx: 0 }}>
      <Link
        color="text.primary"
        to="/"
        key={"home"}
        onClick={(e) => {
          e.preventDefault();
          navigate("/");
        }}
        style={{ textDecoration: "none" }}
        className="styled-link"
      >
        <Typography
          color="text.primary"
          sx={{
            fontSize: "14px",
            fontWeight: 300,
            color: theme.palette.text.secondary,
          }}
        >
          {"Home"}
        </Typography>
      </Link>
      {dashboardInfo && (
        <Typography
          color="text.primary"
          key={"title"}
          sx={{
            fontSize: "14px",
            fontWeight: 300,
            color: theme.palette.text.secondary,
          }}
        >
          {`${dashboardInfo.section} ${
            dashboardInfo?.breadcrumps_sections?.length ? " > " : " - "
          }`}
          <span
            style={{ fontWeight: 300, color: theme.palette.text.secondary }}
          >
            {dashboardInfo.title}
          </span>
        </Typography>
      )}
      {dashboardInfo?.breadcrumps_sections?.length &&
        dashboardInfo?.breadcrumps_sections?.map(
          (data: string, index: number) => (
            <Typography
              color="text.primary"
              key={"custom_section"}
              sx={{
                fontSize: "14px",
                fontWeight:
                  index + 1 === dashboardInfo?.breadcrumps_sections?.length
                    ? 500
                    : 300,
                color:
                  index + 1 === dashboardInfo?.breadcrumps_sections?.length
                    ? "#455A64"
                    : theme.palette.text.secondary,
              }}
            >
              {data}
            </Typography>
          )
        )}
      {sqlLabInfo && (
        <Typography
          color="text.primary"
          key={"sqllab_info"}
          sx={{
            fontSize: "14px",
            fontWeight: 300,
            color: theme.palette.text.secondary,
          }}
        >
          {`SQL Lab - `}
          <span style={{ fontWeight: 500, color: theme.palette.text.primary }}>
            {sqlLabInfo.name && `${sqlLabInfo.name}`}
          </span>
        </Typography>
      )}
    </Breadcrumbs>
  );
}
