import React, { useState } from "react";
import { Grid, Typography, FormHelperText } from "@mui/material";
import {
  LocalizationProvider,
  DatePicker,
  ArrowDropDownIcon,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import theme from "../../../utils/theme";
import { DateRangePickerProps } from "../types";

const formatDate = (date: Dayjs | null): string => {
  return date && date.isValid() ? date.format("MM/YYYY") : "";
};

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  label,
}) => {
  const [isFromDatePickerOpen, setFromDatePickerOpen] = useState(false);
  const [isToDatePickerOpen, setToDatePickerOpen] = useState(false);

  const handleFromDateChange = (newValue: Dayjs | null) => {
    setFromDate(newValue);
  };

  const handleToDateChange = (newValue: Dayjs | null) => {
    setToDate(newValue);
  };

  const showHelperText = (fromDate && !toDate) || (!fromDate && toDate);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid sx={{ mb: "18px" }} direction={"column"} container>
        <Typography
          variant="h6"
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            color: theme.palette.text.primary,
            mb: "8px",
          }}
        >
          {label}
        </Typography>
        <Grid sx={{ mb: "4px" }} item xs={6}>
          <DatePicker
            open={isFromDatePickerOpen}
            onClose={() => setFromDatePickerOpen(false)}
            value={fromDate}
            views={["month", "year"]}
            onChange={handleFromDateChange}
            onAccept={() => setFromDatePickerOpen(false)}
            slotProps={{
              field: { clearable: true },
              textField: {
                placeholder: "From: MM/YYYY",
                InputProps: {
                  endAdornment: (
                    <ArrowDropDownIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
                  ),
                  readOnly: true,
                  value: fromDate ? `From: ${formatDate(fromDate)}` : "",
                },
                onClick: () => setFromDatePickerOpen(true),
                sx: {
                  "& .MuiInputBase-input": {
                    padding: "8px 2px 8px 14px",
                  },
                  "& .MuiOutlinedInput-root ": {
                    pr: "8px",
                  },
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            open={isToDatePickerOpen}
            onClose={() => setToDatePickerOpen(false)}
            views={["month", "year"]}
            value={toDate}
            onChange={handleToDateChange}
            onAccept={() => setToDatePickerOpen(false)}
            slotProps={{
              field: {clearable: true },
              textField: {
                placeholder: "To: MM/YYYY",
                InputProps: {
                  endAdornment: (
                    <ArrowDropDownIcon
                      sx={{ color: "rgba(0, 0, 0, 0.54)", ml: "10px" }}
                    />
                  ),
                  readOnly: true,
                  value: toDate ? `To: ${formatDate(toDate)}` : "",
                },
                onClick: () => setToDatePickerOpen(true),
                sx: {
                  userSelect: "none",
                  "& .MuiInputBase-input": {
                    padding: "8px 2px 8px 14px",
                  },

                  "& .MuiOutlinedInput-root ": {
                    pr: "8px",
                  },
                },
              },
            }}
          />
        </Grid>

        {showHelperText && (
          <Grid item xs={12}>
            <FormHelperText
              sx={{
                color: theme.palette.error.main,
                fontSize: "12px",
                marginTop: "8px",
                textAlign: "end",
              }}
            >
              Date range required.
            </FormHelperText>
          </Grid>
        )}
      </Grid>
    </LocalizationProvider>
  );
};

export default DateRangePicker;
