import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Switch,
  SwitchProps,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useData } from "../contexts/DataContext";
import { DashboardInfo } from "../utils/types";
import DashboardHeader from "./DashboardHeader";
import { styled } from "@mui/material/styles";
import theme from "../utils/theme";
import { KeyboardTab } from "@mui/icons-material";
import Loading from "./Loading";
import { baseUrl } from "../config";
import useFetch from "../hooks/useFetch";
import { AxiosRequestConfig } from "axios";
import { formatAmount } from "../utils/dashboard-utils";

type StyledTableCellProps = TableCellProps & {
  minWidth?: string;
  textAlign?: "left" | "center" | "right";
  backgroundColor?: "#FFDEDF" | "#DEFFE1" | "#FFFFFF";
  fontColor?: "#e6292e" | "#343A40" | "#2190FF";
};

const StyledTableCell = styled(TableCell)<StyledTableCellProps>(
  ({
    theme,
    minWidth = "50px",
    textAlign = "left",
    backgroundColor = "#FFFFFF",
    fontColor = "#343A40",
  }) => ({
    border: `0.5px solid #ECECEC`,
    fontSize: "16px",
    fontWeight: 500,
    color: fontColor,
    minWidth: minWidth,
    textAlign: textAlign,
    backgroundColor: backgroundColor,
  })
);

const StyledTableHeadCell = styled(TableCell)<StyledTableCellProps>(
  ({ theme, textAlign = "center" }) => ({
    borderRight: `0.5px solid ${theme.palette.divider}`,
    fontSize: "16px",
    fontWeight: 500,
    color: "#343A40",
    backgroundColor: "#ECEFF3",
    textAlign: textAlign,
  })
);

const StyledSecondRowTableHeadCell = styled(StyledTableHeadCell)(
  ({ theme, textAlign = "center" }) => ({
    top: "57px",
  })
);

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 38, // Increased length of the switch
  height: 18, // Adjusted height for better proportion
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 1,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)", // Corrected thumb position
      color: "#FFFFFF", // Set thumb color (same for checked and unchecked)
      "& + .MuiSwitch-track": {
        backgroundColor: "#D9D9D9",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#D9D9D9",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 12, // Adjusted thumb width for better proportion
    height: 12, // Adjusted thumb height for better proportion
    backgroundColor: "#FF6969", // Thumb color remains the same
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2, // Updated to match the height
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

type DropdownProps = {
  label: string;
  placeholder: string;
  options: { value: string; label: string }[];
  loading: boolean;
  disabled: boolean;
  error?: string;
};

const SingleSelectDropdown: React.FC<
  DropdownProps & {
    onSelect: (selectedValue: string) => void;
    selectedValue: string | null | undefined;
  }
> = ({
  label,
  placeholder,
  options,
  onSelect,
  selectedValue,
  loading,
  disabled,
  error,
}) => {
  const theme = useTheme();
  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    const selectedValue = value ? value.value : null;
    onSelect(selectedValue);
  };

  return (
    <>
      <Typography
        variant="h6"
        sx={{
          fontSize: "12px",
          fontWeight: 400,
          color: theme.palette.text.primary,
          mb: "8px",
        }}
      >
        {label}
      </Typography>
      <div style={{ position: "relative" }}>
        <Autocomplete
          sx={{ mb: "14px" }}
          options={options}
          getOptionLabel={(option) => option.label}
          value={
            options.find((option) => option.value === selectedValue) || null
          }
          onChange={handleChange}
          autoComplete={true}
          disabled={disabled}
          ListboxProps={{
            style: {
              maxHeight: "150px",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={placeholder}
              size="small"
              error={Boolean(error)}
              helperText={error}
            />
          )}
        />
        {loading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 999,
            }}
          >
            <CircularProgress size={24} />
          </div>
        )}
      </div>
    </>
  );
};

const MultiSelectDropdown: React.FC<
  DropdownProps & {
    onSelect: (selectedValues: string[]) => void;
    selectedValues: string[];
  }
> = ({ label, placeholder, options, onSelect, selectedValues, loading }) => {
  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    const selectedValues = value.map((item: any) => item.value);
    onSelect(selectedValues);
  };

  return (
    <>
      <Typography
        variant="h6"
        sx={{
          fontSize: "12px",
          fontWeight: 400,
          color: theme.palette.text.primary,
          mb: "8px",
        }}
      >
        {label}
      </Typography>
      <div style={{ position: "relative" }}>
        <Autocomplete
          sx={{ mb: "14px" }}
          multiple
          options={options}
          disableCloseOnSelect
          getOptionLabel={(option) => option.label}
          value={options.filter((option) =>
            selectedValues.includes(option.value)
          )}
          onChange={handleChange}
          ListboxProps={{
            style: {
              maxHeight: "150px",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              variant="outlined"
              placeholder={placeholder}
              sx={{
                "& .MuiInputBase-root": {
                  maxHeight: "100px",
                  position: "static",
                  overflowY: "auto",
                },
              }}
            />
          )}
        />
        {loading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 999,
            }}
          >
            <CircularProgress size={24} />
          </div>
        )}
      </div>
    </>
  );
};

type ResponseData =
  | {
      account: string;
      revenue: number | null;
      salary_expense: number | null;
      other_expenses: number | null;
      gross_profit: number | null;
      previous_month_percentage_gross_profit: number | null;
      year: number;
      month: string;
    }
  | null
  | any;

const GrossProfitDetails = () => {
  const location = useLocation();
  // const { state } = location;
  const { dashboardList } = useData();
  const params = useParams();
  const month: string | null = params.month || null;
  const reportType: string | undefined = params.reportType;

  const [dashboardInfo, setDashboardInfo] = useState<DashboardInfo | null>(
    null
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [enableClearButton, setEnableClearButton] = useState(false);
  const [error, setError] = React.useState("");
  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
  const [customerOptions, setCustomerOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [projectOptions, setProjectOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [monthOptions, setMonthOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [yearOptions, setYearOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectedMonth, setSelectedMonth] = useState<string | null>(month);
  const [selectedYear, setSelectedYear] = useState<string | null>("");
  const [appliedFilter, setAppliedFilter] = useState<{
    customer: string[];
    month: string | null;
    year: string;
  }>({
    customer: [],
    month: month,
    year: "",
  });

  const dashboardId = dashboardInfo?.id ?? null;

  const handleAccordionChange = () => {
    setIsExpanded(!isExpanded);
  };

  const clearAllFilters = () => {
    setSelectedCustomers([]);
    setSelectedMonth("");
    setSelectedYear("");
    setEnableButton(true);
  };

  const applyFilter = () => {
    setEnableButton(false);
    setAppliedFilter({
      customer: selectedCustomers || [],
      month: selectedMonth || "",
      year: selectedYear || "",
    });
  };

  useEffect(() => {
    if (
      selectedCustomers.length > 0 ||
      selectedProjects.length > 0 ||
      selectedMonth ||
      selectedYear
    ) {
      setEnableClearButton(true);
    } else {
      setEnableClearButton(false);
    }
  }, [selectedCustomers, selectedProjects, selectedMonth, selectedYear]);

  useEffect(() => {
    if (dashboardList.length === 0) return;

    const dbInfo: DashboardInfo | undefined = dashboardList.find(
      (dashboard) => dashboard.id === 24
    );

    if (!dbInfo) return;
    setDashboardInfo({
      ...dbInfo,
      title:
        reportType === "customer"
          ? `${dbInfo.title} - Showing Customer Profit Data`
          : `${dbInfo.title} - Showing Project Profit Data`,
    });
  }, [dashboardList]);

  const fetchGrossDetails = React.useMemo<AxiosRequestConfig | null>(() => {
    if (!dashboardId) return null;
    return {
      method: "GET",
      url: `${baseUrl}/gross-profit-details?dashboard_id=${dashboardId}&month=${
        appliedFilter.month ? appliedFilter.month : ""
      }&is_customer=${reportType === "customer"}&year=${
        appliedFilter.year ? appliedFilter.year : ""
      }&customer=${reportType === "customer" ? selectedCustomers : ""}`,
    };
  }, [dashboardId, appliedFilter]);

  const fetchProfitDetailsObj = useFetch<{
    data: ResponseData;
    message: string;
  } | null>(fetchGrossDetails);

  useEffect(() => {
    if (fetchProfitDetailsObj?.error) {
      setError("Something went wrong!");
    } else {
      setError("");
    }
  }, [fetchProfitDetailsObj]);

  const fetchCustomerOptions = React.useMemo<AxiosRequestConfig | null>(() => {
    if (!dashboardId) return null;
    return {
      method: "GET",
      url: `${baseUrl}/space-customer-list?dashboard_id=${dashboardId}`,
    };
  }, [dashboardId]);

  const fetchCustomerObj = useFetch<any[] | null>(fetchCustomerOptions);

  useEffect(() => {
    if (fetchCustomerObj?.data) {
      const options = fetchCustomerObj.data.map(({ account }) => ({
        value: account,
        label: account,
      }));
      setCustomerOptions(options);
    }
  }, [fetchCustomerObj?.data]);

  const fetchMonthOptions = React.useMemo<AxiosRequestConfig | null>(() => {
    if (!dashboardId) return null;
    return {
      method: "GET",
      url: `${baseUrl}/months-list?dashboard_id=${dashboardId}`,
    };
  }, [dashboardId]);

  const fetchMonthObj = useFetch<{ months: string[] } | null>(
    fetchMonthOptions
  );

  const fetchYearOptions = React.useMemo<AxiosRequestConfig | null>(() => {
    if (!dashboardId) return null;
    return {
      method: "GET",
      url: `${baseUrl}/years-list?dashboard_id=${dashboardId}`,
    };
  }, [dashboardId]);

  const fetchYearObj = useFetch<{ years: string[] } | null>(fetchYearOptions);

  useEffect(() => {
    if (fetchMonthObj?.data) {
      const options = fetchMonthObj?.data.months.map((month: string) => ({
        value: month,
        label: month,
      }));
      setMonthOptions(options);
    }
  }, [fetchMonthObj?.data]);

  useEffect(() => {
    if (fetchYearObj?.data) {
      const options = fetchYearObj?.data.years.map((month: string) => ({
        value: month,
        label: month,
      }));
      setYearOptions(options);
    }
  }, [fetchYearObj?.data]);

  const handleCustomerChange = (selectedValues: string[]) => {
    setSelectedCustomers(selectedValues);
    setEnableButton(true);
  };

  const handleProjectChange = (selectedValues: string[]) => {
    setSelectedProjects(selectedValues);
    setEnableButton(true);
  };

  const handleMonthChange = (selectedValue: string) => {
    setSelectedMonth(selectedValue);
    setEnableButton(true);
  };

  const handleYearChange = (selectedValue: string) => {
    setSelectedYear(selectedValue);
    setEnableButton(true);
  };

  const response: ResponseData = fetchProfitDetailsObj.data;

  return (
    <>
      <Box>
        {dashboardInfo && <DashboardHeader dashboardInfo={dashboardInfo} />}
      </Box>

      <Stack>
        <Box sx={{ display: "flex", m: "10px" }}>
          <Box sx={{ mr: "10px" }}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Accordion
                expanded={isExpanded}
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  mr: "10px !important",
                  borderRadius: "8px",
                  mb: "12px",
                }}
              >
                <AccordionSummary
                  sx={{ maxHeight: "30px", minHeight: "unset !important" }}
                >
                  <Box sx={{ flex: 1, display: "flex" }}></Box>
                  <Box
                    onClick={handleAccordionChange}
                    sx={{
                      overflow: "hidden",
                      position: "absolute",
                      right: "4px",
                      top: "4px",
                      maxWidth: "24px !important",
                      height: "20px",
                      p: 0,
                    }}
                  >
                    {isExpanded ? (
                      <KeyboardTab sx={{ transform: "rotate(180deg)" }} />
                    ) : (
                      <KeyboardTab color="primary" />
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ flexDirection: "row", display: "flex" }}
                >
                  {isExpanded && (
                    <Box
                      sx={{
                        width: `${isExpanded ? "12vw" : "0.6vw"}`,
                        pb: "10px",
                        mb: "10px",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "18px",
                          fontWeight: 500,
                          mb: "14px",
                          color: theme.palette.text.primary,
                        }}
                      >
                        Filters
                      </Typography>

                      {reportType === "customer" && (
                        <MultiSelectDropdown
                          label={"Customer"}
                          placeholder={`${customerOptions.length} Options`}
                          options={customerOptions}
                          onSelect={handleCustomerChange}
                          selectedValues={selectedCustomers}
                          loading={fetchCustomerObj.loading}
                          disabled={false}
                        />
                      )}
                      {reportType !== "customer" && (
                        <MultiSelectDropdown
                          label={"Project"}
                          placeholder={`${projectOptions.length} Options`}
                          options={projectOptions}
                          onSelect={handleProjectChange}
                          selectedValues={selectedProjects}
                          loading={fetchCustomerObj.loading}
                          disabled={false}
                        />
                      )}

                      <SingleSelectDropdown
                        label="Month"
                        placeholder={`${monthOptions.length} Options`}
                        options={monthOptions}
                        onSelect={handleMonthChange}
                        selectedValue={selectedMonth}
                        loading={fetchMonthObj.loading}
                        disabled={false}
                      />
                      <SingleSelectDropdown
                        label="Year"
                        placeholder={`${yearOptions.length} Options`}
                        options={yearOptions}
                        onSelect={handleYearChange}
                        selectedValue={selectedYear}
                        loading={fetchYearObj.loading}
                        disabled={false}
                      />

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          mt: "10px",
                        }}
                      >
                        <Button
                          onClick={applyFilter}
                          disabled={!enableButton}
                          sx={{
                            maxWidth: "180px",
                            textTransform: "none",
                            mr: "10px",
                            py: "4px",
                            px: "12px",
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Apply
                        </Button>
                        <Button
                          sx={{
                            textTransform: "none",
                            color: "#343A40",
                            borderColor: "#343A40",
                            py: "4px",
                            px: "12px",
                          }}
                          onClick={clearAllFilters}
                          disabled={!enableClearButton}
                          variant="outlined"
                        >
                          Clear filters
                        </Button>
                      </Box>
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: `${isExpanded ? "84vw" : "95.6vw"}`,
                  mb: "4px !important",
                  position: "relative",
                }}
              >
                <TableContainer
                  component={Paper}
                  elevation={0}
                  sx={{
                    height: "74vh",
                    boxShadow: "none",
                    borderRadius: "8px",
                    mb: "10px",
                    border: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  {error && error !== "" ? (
                    <Typography
                      sx={{
                        justifyContent: "center",
                        width: `100%`,
                        height: `100%`,
                        alignItems: "center",
                        display: "flex",
                        color: "#343a40",
                      }}
                    >
                      {error}
                    </Typography>
                  ) : (
                    <Table
                      className={"table"}
                      aria-label="eva table"
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableHeadCell textAlign="left" rowSpan={2}>
                            {reportType === "customer" ? "Customer" : "Project"}
                          </StyledTableHeadCell>
                          <StyledTableHeadCell colSpan={4} rowSpan={1}>
                            {appliedFilter.year
                              ? appliedFilter.year
                              : new Date().getFullYear()}
                          </StyledTableHeadCell>
                        </TableRow>
                        <TableRow>
                          <StyledSecondRowTableHeadCell rowSpan={1}>
                            Revenue
                          </StyledSecondRowTableHeadCell>
                          <StyledSecondRowTableHeadCell rowSpan={1}>
                            Salary Expenses
                          </StyledSecondRowTableHeadCell>
                          <StyledSecondRowTableHeadCell rowSpan={1}>
                            Other Expenses
                          </StyledSecondRowTableHeadCell>
                          <StyledSecondRowTableHeadCell rowSpan={1}>
                            Gross Profit
                          </StyledSecondRowTableHeadCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {response && response.length > 0 ? (
                          response.map((row: any, index: any) => (
                            <TableRow key={index}>
                              <StyledTableCell textAlign="left">
                                {row.account}
                              </StyledTableCell>
                              <StyledTableCell textAlign="center">
                                {formatAmount(row.revenue)}
                              </StyledTableCell>
                              <StyledTableCell textAlign="center">
                                {formatAmount(row.salary_expense)}
                              </StyledTableCell>
                              <StyledTableCell textAlign="center">
                                {formatAmount(row.other_expenses)}
                              </StyledTableCell>
                              <StyledTableCell textAlign="center">
                                {formatAmount(row.gross_profit)}
                              </StyledTableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <StyledTableCell textAlign="center" colSpan={17}>
                              {response?.length === 0
                                ? "No data"
                                : fetchProfitDetailsObj?.data?.message}
                            </StyledTableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>

                <Loading open={fetchProfitDetailsObj.loading} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default GrossProfitDetails;
