import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import axiosConfig from "./axiosConfig";
import {
  ValueFilter,
  ValuesFilter,
  CheckboxFilter,
  Option,
  Filter,
} from "./types";
import { ValueWhereClause, ValuesWhereClause } from "./types";
import { baseUrl } from "../config";
import { ExportAction } from "../modules/charts/types";

dayjs.extend(customParseFormat);

export function getDefaultValueWhereClause(
  params: ValueFilter,
  value: Option | null
): ValueWhereClause | null {
  if (params.type !== "value" || value === null) return null;
  return { column: params.column, operator: "eq", value };
}

export function getDefaultValuesWhereClause(
  params: ValuesFilter,
  value: Option[]
): ValuesWhereClause | null {
  if (params.type !== "values" || value.length === 0) return null;
  return { column: params.column, operator: "in", value };
}

export function getDefaultCheckboxWhereClause(
  params: CheckboxFilter,
  value: boolean
): ValueWhereClause | null {
  if (params.type !== "checkbox") return null;
  if (!value) return null;
  return { column: params.column, operator: "eq", value: params.option };
}

export function getLast12Months(): { col: string; label: string }[] {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const result: { col: string; label: string }[] = [];
  let monthIndex = currentMonth - 2; // Start from the month before the last month
  let year = currentYear;

  // If the calculated start month is less than January, adjust the year and month index
  if (monthIndex < 0) {
    monthIndex += 12;
    year -= 1;
  }

  // Generate 12 months in descending order
  for (let i = 0; i < 12; i++) {
    result.push({
      col: months[monthIndex],
      label: `${months[monthIndex]} ${year}`,
    });

    // Move to the previous month
    monthIndex -= 1;
    if (monthIndex < 0) {
      monthIndex = 11;
      year -= 1;
    }
  }

  return result;
}

export function convertToDate(monthYearString: string): string {
  const input = monthYearString
    .toLowerCase()
    .replace(/\b(\w)/g, (s) => s.toUpperCase());
  const parsedDate = dayjs(input, "MMM YYYY");
  if (!parsedDate.isValid()) {
    throw new Error("Invalid date format");
  }
  return parsedDate.format("YYYY-MM-DD");
}

export function addSuffix(day: number): string {
  let suffix: string;
  if (day === 1 || day === 21 || day === 31) {
    suffix = "st";
  } else if (day === 2 || day === 22) {
    suffix = "nd";
  } else if (day === 3 || day === 23) {
    suffix = "rd";
  } else {
    suffix = "th";
  }
  return suffix;
}

export function formattedDateWithSuffix(date: string): string {
  const dateObject = new Date(date);
  const day = dateObject.getDate();
  const month = dateObject.toLocaleString("default", { month: "short" });
  const year = dateObject.getFullYear();
  const dayWithSuffix = day + addSuffix(day);
  return `${dayWithSuffix} ${month} ${year}`;
}

export function getAllocationDetails(record: {
  allocation_percentage: number;
  allocation_type: string;
  start_date: string;
  end_date?: string;
  project_name: string;
}): string {
  const startDate = formattedDateWithSuffix(record.start_date);
  const endDate = record.end_date
    ? formattedDateWithSuffix(record.end_date)
    : "now";
  return `${record.allocation_percentage}% (${record.allocation_type}) from ${startDate} to ${endDate} - ${record.project_name}`;
}

export function formatAmount(value: any) {
  return value != null ? value.toLocaleString("en-IN") : ("NA" as string);
}

export const handleExport: ExportAction = (
  dashboardId,
  datasetId,
  title,
  filters,
  appliedFilterValues,
  dashboardControls
) => {
  const body = {
    dashboard_id: dashboardId,
    filter_column: null,
    filters: filters
      .filter((filter) => {
        if (!(filter.id in appliedFilterValues)) return false;
        if (filter.type === "value") {
          return appliedFilterValues[filter.id] === null ? false : true;
        }
        if (filter.type === "values") {
          return appliedFilterValues[filter.id].length === 0 ? false : true;
        }
        if (filter.type === "checkbox") {
          return appliedFilterValues[filter.id];
        }
        return false;
      })
      .map((filter: Filter) => {
        return filter.type === "value"
          ? filter.getWhereClause(
              filter as ValueFilter,
              appliedFilterValues[filter.id]
            )
          : filter.type === "values"
          ? filter.getWhereClause(
              filter as ValuesFilter,
              appliedFilterValues[filter.id]
            )
          : filter.getWhereClause(
              filter as CheckboxFilter,
              appliedFilterValues[filter.id]
            );
      })
      .filter((whereClause) => whereClause !== null),
    pagination: null,
    export_to_excel: true,
  };

  let newDatasetId = datasetId;
  if (dashboardControls["datasetSwitchControl"]) {
    const control = dashboardControls["datasetSwitchControl"];
    newDatasetId = control.value;
  }

  axiosConfig({
    method: "POST",
    url: `${baseUrl}/explore_chart_data/${newDatasetId}`,
    data: body,
    responseType: "blob",
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", `${title}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error) => {
      console.error("There was an error downloading the file:", error);
    });
};
