const scrollbarStyle = {
  body: {
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#DEDEDE",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#A4A3A3",
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#888",
    },
  },
};

export default scrollbarStyle;
