export const debounce = (callback: any, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

export const isNumber = (value: any): boolean => {
  if (typeof value === "number") return true;
  if (typeof value === "string") return !isNaN(Number(value.trim()));
  return false;
};
