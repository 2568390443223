import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleAuth } from "../contexts/GoogleAuthContext";
import { Role, hasRequiredRole } from "../utils/roles";

interface PrivateRouteProps {
  children: any;
  roles: Role[];
}

export const PrivateRoute = ({ children, roles }: PrivateRouteProps) => {
  const { accessToken, userRoles } = useGoogleAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken) return navigate("/login");
    if (!hasRequiredRole(userRoles, roles)) return navigate("/403");
  }, [navigate, roles, accessToken, userRoles]);

  return children;
};

export default PrivateRoute;
