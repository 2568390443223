import { metricsInputGroup } from "../inputs";
import { EChartsOption } from "echarts";
import { ChartDef } from "../types";

import HdrAutoRoundedIcon from "@mui/icons-material/HdrAutoRounded";
import { sortData } from "../common";

export const bigTextChartDef: ChartDef = {
  type: "big-text",
  builder: "echart",
  label: "Big Text",
  Icon: HdrAutoRoundedIcon,
  controls: {
    metrics: {
      id: "metrics",
      title: "Metrics",
      inputGroup: metricsInputGroup,
      inputGroups: [],
      maxGroups: 1,
      required: true,
    },
  },
  buildChartOption(controlLabels, rows, chartConfig) {
    const { metricsLabels } = controlLabels;
    const { sortType, sortColumn, format } = chartConfig;

    const sortedRows = sortData(rows, sortType, sortColumn);

    const option: EChartsOption = {
      graphic: {
        elements: [
          {
            type: "text",
            left: "left",
            top: "top",
            style: {
              text: format
                ? format(sortedRows[0][metricsLabels[0]])
                : sortedRows[0][metricsLabels[0]],
              fontSize: 74,
              fontWeight: 400,
            },
          },
        ],
      },
    };

    return option;
  },
};
