import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import {
  Box,
  Switch,
  SwitchProps,
  TableCell,
  TableCellProps,
  useTheme,
} from "@mui/material";
import { AxiosRequestConfig } from "axios";
import { baseUrl } from "../config";
import useFetch from "../hooks/useFetch";
import Loading from "../components/Loading";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "../utils/dashboard-utils";

type ProjectTableProps = { dashboardId: number };

type StyledTableCellProps = TableCellProps & {
  minWidth?: string;
  textAlign?: "left" | "center" | "right";
  backgroundColor?: "#FFDEDF" | "#DEFFE1" | "#FFFFFF";
  fontColor?: "#e6292e" | "#343A40" | "#2190FF";
  hoverBackgroundColor?: string;
};

const StyledTableCell = styled(TableCell)<StyledTableCellProps>(
  ({
    theme,
    minWidth = "50px",
    textAlign = "left",
    backgroundColor = "#FFFFFF",
    fontColor = "#343A40",
    hoverBackgroundColor,
  }) => ({
    border: `0.5px solid #ECECEC`,
    fontSize: "16px",
    fontWeight: 500,
    color: fontColor,
    minWidth: minWidth,
    textAlign: textAlign,
    backgroundColor: backgroundColor,
    "&:hover": hoverBackgroundColor
      ? {
          backgroundColor: hoverBackgroundColor,
        }
      : {},
  })
);

const StyledTableHeadCell = styled(TableCell)<StyledTableCellProps>(
  ({ theme, textAlign = "center" }) => ({
    borderRight: `0.5px solid ${theme.palette.divider}`,
    fontSize: "16px",
    fontWeight: 500,
    color: "#343A40",
    backgroundColor: "#ECEFF3",
    textAlign: textAlign,
  })
);

const StickyTableCell = styled(StyledTableCell)({
  position: "sticky",
  left: 0,
  background: "#fff",
  zIndex: 1,
  cursor: "pointer",
});

const StickyTableHeadCell = styled(StyledTableHeadCell)({
  position: "sticky",
  left: 0,
  zIndex: 3,
});

const StyledSecondRowTableHeadCell = styled(StyledTableHeadCell)(
  ({ theme, textAlign = "center" }) => ({
    top: "57px",
  })
);

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 38,
  height: 18,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 1,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#FFFFFF",
      "& + .MuiSwitch-track": {
        backgroundColor: "#D9D9D9",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#E6292E",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#000000",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 12,
    height: 12,
    backgroundColor: "#E6292E",
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

type MonthData = {
  revenue: number;
  salary_expenses: number;
  other_expenses: number | null;
  gross_profit: number;
};

type YearData = {
  [month: string]: MonthData;
};

type Data =
  | {
      [year: string]: YearData;
    }
  | null
  | any;
export const GrossProfitComparison: React.FC<ProjectTableProps> = ({
  dashboardId,
}) => {
  const theme = useTheme();
  const [error, setError] = React.useState("");
  const [customerToggle, setCustomerToggle] = useState(true);
  const navigate = useNavigate();

  const handleSwitchToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerToggle(event.target.checked);
    console.log(event.target.checked ? "Checked" : "Unchecked");
  };

  const generateMonthsArray = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames;
  };

  const months = generateMonthsArray();

  const handleCellClick = (value: string) => {
    navigate(
      `/dashboard/24/detail/${value}/${
        customerToggle ? "customer" : "project"
      }`,
      {
        state: { month: value, customer: customerToggle },
      }
    );
  };

  const fetchGrossProfitData = React.useMemo<AxiosRequestConfig | null>(() => {
    return {
      method: "GET",
      url: `${baseUrl}/gross-profit?dashboard_id=${dashboardId}`,
    };
  }, [dashboardId]);

  const fetchEVATrendsDataObj = useFetch<{
    data: Data;
    message: string;
  } | null>(fetchGrossProfitData);

  useEffect(() => {
    if (fetchEVATrendsDataObj?.error) {
      setError("Something went wrong!");
    } else {
      setError("");
    }
  }, [fetchEVATrendsDataObj]);

  const data: Data = fetchEVATrendsDataObj?.data;
  const years = [
    new Date().getFullYear().toString(),
    (new Date().getFullYear() - 1).toString(),
    (new Date().getFullYear() - 2).toString(),
    (new Date().getFullYear() - 3).toString(),
    (new Date().getFullYear() - 4).toString(),
  ];
  const currentYear = new Date().getFullYear().toString();

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Box
        sx={{
          p: 1,
          display: "flex",
          position: "absolute",
          flexDirection: "row",
          top: "120px",
          right: "30px",
        }}
      >
        <Typography color={"#000000"} fontSize={"12px"}>
          Project
        </Typography>
        <IOSSwitch
          sx={{ mx: "12px" }}
          checked={customerToggle}
          onChange={handleSwitchToggle}
        />
        <Typography color={"#000000"} fontSize={"12px"}>
          Customer
        </Typography>
      </Box>
      <Box sx={{ px: 3, mt: 2, width: "100%" }}>
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            height: "calc(100vh - 264px)",
            width: `calc(100vw - ${theme.spacing(6)})`,
            boxShadow: "none",
            borderRadius: "8px",
            mb: "10px",
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          {error && error !== "" ? (
            <Typography
              sx={{
                justifyContent: "center",
                width: `100%`,
                height: `100%`,
                alignItems: "center",
                display: "flex",
                color: "#343a40",
              }}
            >
              {error}
            </Typography>
          ) : (
            <Table className={"table"} aria-label="eva table" stickyHeader>
              <TableHead>
                <TableRow>
                  <StickyTableHeadCell textAlign="left" rowSpan={2}>
                    Month
                  </StickyTableHeadCell>
                  <StyledTableHeadCell colSpan={4} rowSpan={1}>
                    {new Date().getFullYear()}
                  </StyledTableHeadCell>
                  <StyledTableHeadCell colSpan={2} rowSpan={1}>
                    {new Date().getFullYear() - 1}
                  </StyledTableHeadCell>
                  <StyledTableHeadCell colSpan={2} rowSpan={1}>
                    {new Date().getFullYear() - 2}
                  </StyledTableHeadCell>
                  <StyledTableHeadCell colSpan={2} rowSpan={1}>
                    {new Date().getFullYear() - 3}
                  </StyledTableHeadCell>
                  <StyledTableHeadCell colSpan={2} rowSpan={1}>
                    {new Date().getFullYear() - 4}
                  </StyledTableHeadCell>
                </TableRow>
                <TableRow>
                  <StyledSecondRowTableHeadCell rowSpan={1}>
                    Revenue
                  </StyledSecondRowTableHeadCell>
                  <StyledSecondRowTableHeadCell rowSpan={1}>
                    Salary Expenses
                  </StyledSecondRowTableHeadCell>
                  <StyledSecondRowTableHeadCell rowSpan={1}>
                    Other Expenses
                  </StyledSecondRowTableHeadCell>
                  <StyledSecondRowTableHeadCell rowSpan={1}>
                    Gross Profit
                  </StyledSecondRowTableHeadCell>

                  <StyledSecondRowTableHeadCell rowSpan={1}>
                    Revenue
                  </StyledSecondRowTableHeadCell>
                  <StyledSecondRowTableHeadCell rowSpan={1}>
                    Gross Profit
                  </StyledSecondRowTableHeadCell>
                  <StyledSecondRowTableHeadCell rowSpan={1}>
                    Revenue
                  </StyledSecondRowTableHeadCell>
                  <StyledSecondRowTableHeadCell rowSpan={1}>
                    Gross Profit
                  </StyledSecondRowTableHeadCell>
                  <StyledSecondRowTableHeadCell rowSpan={1}>
                    Revenue
                  </StyledSecondRowTableHeadCell>
                  <StyledSecondRowTableHeadCell rowSpan={1}>
                    Gross Profit
                  </StyledSecondRowTableHeadCell>
                  <StyledSecondRowTableHeadCell rowSpan={1}>
                    Revenue
                  </StyledSecondRowTableHeadCell>
                  <StyledSecondRowTableHeadCell rowSpan={1}>
                    Gross Profit
                  </StyledSecondRowTableHeadCell>
                </TableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      months.map((month) => (
                        <TableRow key={month}>
                          <StickyTableCell
                            hoverBackgroundColor="#DDE2E6"
                            onClick={() => handleCellClick(month)}
                            minWidth="200px"
                            fontColor="#2190FF"
                            component="th"
                            scope="row"
                            textAlign="left"
                          >
                            {month}
                          </StickyTableCell>
                          <StyledTableCell textAlign="center">
                            {formatAmount(data[currentYear]?.[month]?.revenue)}
                          </StyledTableCell>
                          <StyledTableCell textAlign="center">
                            {formatAmount(
                              data[currentYear]?.[month]?.salary_expenses
                            )}
                          </StyledTableCell>
                          <StyledTableCell textAlign="center">
                            {formatAmount(
                              data[currentYear]?.[month]?.other_expenses
                            )}
                          </StyledTableCell>
                          <StyledTableCell textAlign="center">
                            {formatAmount(
                              data[currentYear]?.[month]?.gross_profit
                            )}
                          </StyledTableCell>
                          {years
                            .filter((year) => year !== currentYear)
                            .map((year) => (
                              <React.Fragment key={year}>
                                <StyledTableCell textAlign="center">
                                  {formatAmount(
                                    data[year as keyof Data]?.[month]?.revenue
                                  )}
                                </StyledTableCell>
                                <StyledTableCell textAlign="center">
                                  {formatAmount(
                                    data[year as keyof Data]?.[month]
                                      ?.gross_profit
                                  )}
                                </StyledTableCell>
                              </React.Fragment>
                            ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            <Loading open={fetchEVATrendsDataObj.loading} />
          </Box>
        </Box>
  );
};
export default GrossProfitComparison;
