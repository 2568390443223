import { Box, GlobalStyles } from "@mui/material";
import Footer from "../layouts/Footer";
import AppBar from "../layouts/AppBar";

const Layout = ({ children }: any) => (
  <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
    <AppBar />
    {children}
    <Footer />
  </Box>
);

export default Layout;
