import { useEffect, useMemo, useRef } from "react";

import { Box, Stack, Typography } from "@mui/material";
import { Button, Chip, IconButton } from "@mui/material";

import { IMenuItem, PreControls } from "../modules/charts/types";
import { AxiosRequestConfig } from "axios";
import { SavedQuery } from "../utils/types";
import { TableColumnControlGroupValue } from "../modules/charts/types";

import { aggregationItems } from "../modules/charts/common";
import { baseUrl } from "../config";
import { chartDefs } from "../modules/charts";
import { useChartBuilder } from "../contexts/ChartBuilderContext";

import ChartControl from "./ChartControl";
import Loading from "./Loading";
import Echart from "./EChart";
import CustomTable from "./CustomTable";
import CloseRounded from "@mui/icons-material/CloseRounded";

import useFetch from "../hooks/useFetch";

interface DataVisualizerProps {
  handleClose: () => void;
  savedQuery: SavedQuery;
}

export default function DataVisualizer({
  handleClose,
  savedQuery,
}: DataVisualizerProps) {
  const defaultValueFetched = useRef<boolean>(false);

  const {
    sql,
    builder,
    controls,
    chartType,
    buildSql,
    changeChartType,
    hasRequiredValues,
    addControlInputGroup,
    addPreControlInputGroups,
  } = useChartBuilder();

  const fetchDataOptions = useMemo<AxiosRequestConfig | null>(() => {
    if (!savedQuery || !sql) return null;
    return {
      method: "POST",
      url: `${baseUrl}/run-query/`,
      data: {
        database_id: savedQuery.database_id,
        sql: sql,
        chart_id: savedQuery.chart_id,
        dashboard_id: savedQuery.dashboard_id,
      },
    };
  }, [savedQuery, sql]);
  const fetchDataObj = useFetch<any>(fetchDataOptions);

  const colItems = useMemo<IMenuItem[]>(
    () =>
      savedQuery.data?.columns.map((col: any) => ({
        value: col.field,
        label: col.field,
      })) || [],
    [savedQuery.data]
  );

  const preControlsReq = useMemo<AxiosRequestConfig | null>(() => {
    if (defaultValueFetched.current === true) return null;

    const columnsArr = colItems.map((col) => col.value);
    const aggregations = aggregationItems.map((agg) => agg.value);

    return {
      method: "POST",
      url: `${baseUrl}/chart-default-value`,
      data: { chartType, columns: columnsArr, metrics: aggregations },
    };
    // eslint-disable-next-line
  }, [chartType]);
  const preControlsObj = useFetch<any>(preControlsReq);

  useEffect(() => {
    if (!preControlsObj.data) return;
    if (defaultValueFetched.current) return;
    defaultValueFetched.current = true;

    const data = preControlsObj.data;
    const preControls: PreControls = {
      "x-axis": [
        {
          column: data["x_axis"] || "",
          label: data["x_axis"] || "",
        },
      ],
      dimension: [
        {
          column: data["dimensions"],
          label: data["dimensions"],
        },
      ],
      metrics: [
        {
          column: data["metrics_column"] || "",
          aggregation: data["metrics"] || "",
          label: "metric",
        },
      ],
    };

    addPreControlInputGroups(preControls);
    buildSql(savedQuery.sql);

    // eslint-disable-next-line
  }, [preControlsObj.data]);

  const columns = useMemo<TableColumnControlGroupValue[]>(() => {
    const _cols: TableColumnControlGroupValue[] = [];
    Object.values(controls).forEach((_control) => {
      _control.inputGroups.forEach((_inputGroup) => {
        if (_inputGroup.groupType !== "table-column") return;

        const _col: TableColumnControlGroupValue = {
          column: _inputGroup.inputs["column"].value,
          label: _inputGroup.inputs["label"].value,
          parentColumns: _inputGroup.inputs["parentColumns"].value,
          width: _inputGroup.inputs["width"].value,
        };

        _cols.push(_col);
      });
    });
    return _cols;
  }, [controls]);

  return (
    <>
      <Loading type="spinner" open={preControlsObj.loading} />
      <Box p={2} sx={{ width: "60vw" }}>
        {/* Header */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 500,
            }}
          >
            {"Explore Charts"}
          </Typography>
          <IconButton onClick={() => handleClose()}>
            <CloseRounded />
          </IconButton>
        </Stack>

        {/* Chart Controls */}
        <Stack direction="column" mt={2}>
          <Stack direction="column">
            <Typography>Chart Type</Typography>
            <Stack direction="row" spacing={1} mt={1}>
              {Object.values(chartDefs).map(({ label, type, Icon }) => (
                <Chip
                  key={type}
                  label={label}
                  size="small"
                  color={chartType === type ? "primary" : "default"}
                  icon={<Icon />}
                  onClick={() => changeChartType(type)}
                  variant={chartType === type ? "filled" : "outlined"}
                />
              ))}
            </Stack>
          </Stack>

          {/* Control Panel */}
          <Box mt={2}>
            <Typography>Control Panel</Typography>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={2}
              pb={1}
              mt={1}
              sx={{ overflowX: "auto" }}
            >
              {Object.values(controls).map((control) => (
                <ChartControl
                  key={control.id}
                  colItems={colItems}
                  controlId={control.id}
                  addControlInputGroup={addControlInputGroup}
                />
              ))}
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              mt={1}
            >
              <Button
                variant="contained"
                size="small"
                onClick={() => buildSql(savedQuery.sql)}
                disabled={!hasRequiredValues()}
                sx={{ width: "200px" }}
              >
                Visualize
              </Button>
            </Stack>
          </Box>
        </Stack>

        {/* Visualization */}
        <Stack mt={2} sx={{ height: "100%", width: `100%` }}>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%", width: `100%` }}
          >
            {fetchDataObj.error ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
              >
                <Typography color="error">
                  {fetchDataObj.error.message}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ height: "100%", width: `100%` }}>
                {builder === "echart" && (
                  <Echart
                    rows={fetchDataObj.data?.data?.data}
                    loading={fetchDataObj.loading}
                    chartConfig={{ sortType: "none" }}
                  />
                )}
                {builder === "custom-table" &&
                  fetchDataObj.data?.data?.data && (
                    <CustomTable
                      rows={fetchDataObj.data.data.data}
                      columns={columns}
                      loading={fetchDataObj.loading}
                    />
                  )}
              </Box>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
