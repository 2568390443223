//hooks
import { useEffect, useMemo, useState } from "react";
import { useDashboardFilter } from "../contexts/DashboardFilterContext";
import { useTheme } from "@mui/material";

//types
import { Filter, DashboardControls, Option } from "../utils/types";
import { ValueFilter, ValuesFilter, CheckboxFilter } from "../utils/types";
import { CustomChartProps } from "../modules/charts/types";

//ui components
import { Box, Checkbox, TextField, Typography } from "@mui/material";
import { Autocomplete, FormControlLabel } from "@mui/material";

//utils
import { baseUrl } from "../config";
import axiosConfig from "../utils/axiosConfig";

export interface IDashboardFilterFieldProps {
  filter: Filter;
  charts: CustomChartProps[];
  dashboardId: number;
  dashboardControls: DashboardControls;
}

export default function DashboardFilterField({
  filter,
  charts,
  dashboardId,
  dashboardControls,
}: IDashboardFilterFieldProps) {
  const theme = useTheme();

  const { id, type, name, required } = filter;
  const { filters, filterValues, lastUpdatedFilter, updateFilterValue } =
    useDashboardFilter();

  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<Option[]>([]);

  const enabled = useMemo<boolean>(() => {
    const hasEveryRequiredFitlerValues = filter.requiredFilterIds.every(
      (_filterId) => {
        const filterValue = filterValues[_filterId];
        if (filterValue === null || filterValue === undefined) return false;

        if (
          typeof filterValue === "string" ||
          typeof filterValue === "number"
        ) {
          return filterValue === "" ? false : true;
        } else if (typeof filterValue === "object") {
          return "length" in filterValue && filterValue.length > 0
            ? true
            : false;
        } else if (typeof filterValue === "boolean") {
          return filterValue;
        }
        return false;
      }
    );

    if (!filter.disablingFilterIds) return hasEveryRequiredFitlerValues;

    const hasSomeDisablingFilterValues = filter.disablingFilterIds.some(
      (_filterId) => {
        const filterValue = filterValues[_filterId];
        if (filterValue === null || filterValue === undefined) return true;

        if (
          typeof filterValue === "string" ||
          typeof filterValue === "number"
        ) {
          return filterValue === "" ? true : false;
        } else if (typeof filterValue === "object") {
          return "length" in filterValue && filterValue.length > 0
            ? false
            : true;
        } else if (typeof filterValue === "boolean") {
          return !filterValue;
        }
        return true;
      }
    );

    return hasEveryRequiredFitlerValues && hasSomeDisablingFilterValues;
  }, [filter, filterValues]);

  useEffect(() => {
    if (!enabled) return;
    if (filter.type !== "value" && filter.type !== "values") return;
    if (filter.options !== null) return setOptions(filter.options);

    if (
      lastUpdatedFilter &&
      !filter.cascadedFilterIds.includes(lastUpdatedFilter.id)
    )
      return;

    const promises: Promise<any>[] = [];

    filter.appliesToChartIds.forEach((_chartId) => {
      const body = {
        dashboard_id: dashboardId,
        filter_column: filter.column,
        filters: filter.cascadedFilterIds
          .map((_filterId) => {
            const _filter = filters.find((_fltr) => _fltr.id === _filterId)!;
            const value = filterValues[_filterId];
            return _filter.type === "value"
              ? _filter.getWhereClause(_filter as ValueFilter, value)
              : _filter.type === "values"
              ? _filter.getWhereClause(_filter as ValuesFilter, value)
              : _filter.getWhereClause(_filter as CheckboxFilter, value);
          })
          .filter((whereClause) => whereClause !== null),
        pagination: null,
      };

      const chart = charts.find((_chart) => _chart.id === _chartId)!;
      let newDatasetId = chart.datasetId;
      if (dashboardControls["datasetSwitchControl"]) {
        const control = dashboardControls["datasetSwitchControl"];
        newDatasetId = control.value;
      }

      const fetchDataOption = {
        method: "POST",
        url: `${baseUrl}/explore_chart_data/${newDatasetId}`,
        data: body,
      };

      promises.push(axiosConfig(fetchDataOption));
    });

    setLoading(true);

    Promise.all(Object.values(promises))
      .then((results) => {
        const _options: Option[] = results.reduce((acc, result) => {
          if (result.data.rows && result.data.rows.length > 0) {
            return acc.concat(
              result.data.rows.map((row: any) => row[filter.column])
            );
          }
          return acc;
        }, []);

        let uniqOptions = Array.from(
          new Set(_options.filter((_opt) => _opt !== null))
        ).sort();

        if (filter.formatOptions) {
          uniqOptions = filter.formatOptions(uniqOptions);
        }

        setOptions(uniqOptions);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });

    return () => {
      setLoading(false);
    };
    // eslint-disable-next-line
  }, [
    charts,
    enabled,
    filter,
    filters,
    lastUpdatedFilter,
    dashboardId,
    dashboardControls,
  ]);

  return (
    <Box>
      {(type === "value" || type === "values") && (
        <Autocomplete
          multiple={type === "values"}
          id={filter.id}
          size="small"
          disabled={!enabled}
          options={options}
          filterSelectedOptions
          value={filterValues[id]}
          onChange={(event: any, newValue: any) =>
            updateFilterValue(id, newValue)
          }
          renderInput={(params) => (
            <TextField
              required={required}
              {...params}
              label={name}
              placeholder={loading ? `Loading...` : `${options.length} Options`}
            />
          )}
        />
      )}
      {type === "checkbox" && (
        <FormControlLabel
          required={required}
          disabled={!enabled}
          control={
            <Checkbox
              checked={filterValues[id]}
              onChange={(e) => updateFilterValue(id, e.target.checked)}
              sx={{ py: 0 }}
            />
          }
          label={
            <Typography
              fontSize={14}
              sx={{
                color: enabled
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary,
              }}
            >
              {name}
            </Typography>
          }
        />
      )}
    </Box>
  );
}
