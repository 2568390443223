import { useEffect, useRef } from "react";
import {
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { ControlId, IMenuItem } from "../modules/charts/types";
import { useChartBuilder } from "../contexts/ChartBuilderContext";
import ControlInputModal from "./ControlInputModal";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import useToggle from "../hooks/useToggle";

export interface IControlInputGroupProps {
  colItems: IMenuItem[];
  controlId: ControlId;
  inputGroupIdx: number;
}

export default function ControlInputGroup({
  colItems,
  controlId,
  inputGroupIdx,
}: IControlInputGroupProps) {
  const initiallyOpened = useRef<boolean>(false);

  const theme = useTheme();
  const { controls, removeControlInputGroup } = useChartBuilder();

  const control = controls[controlId]!;
  const inputGroup = control.inputGroups[inputGroupIdx];

  const { open, handleClose, handleOpen } = useToggle();

  function handleRemove(e: any) {
    e.stopPropagation();
    removeControlInputGroup(controlId, inputGroupIdx);
  }

  useEffect(() => {
    if (initiallyOpened.current) return;
    if (inputGroup.inputs.column.value === "") handleOpen();
    initiallyOpened.current = true;
  });

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width={"100%"}
        py={0.5}
        sx={{
          borderRadius: "4px",
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.grey[200],
          cursor: "pointer",
        }}
        onClick={handleOpen}
      >
        <Stack direction="row" alignItems="center">
          <IconButton
            size="small"
            sx={{ py: 0, px: 0.5 }}
            onClick={handleRemove}
          >
            <CloseRoundedIcon />
          </IconButton>
          <Divider orientation="vertical" flexItem />
          <Typography
            fontSize={14}
            noWrap
            ml={1}
            sx={
              inputGroup.inputs.label.value
                ? {}
                : { color: theme.palette.error.main }
            }
          >
            {inputGroup.inputs.label.value || "Click to select a value"}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Divider orientation="vertical" flexItem />
          <IconButton size="small" sx={{ py: 0, px: 0.5 }}>
            <ArrowForwardIosRoundedIcon />
          </IconButton>
        </Stack>
      </Stack>

      <ControlInputModal
        colItems={colItems}
        open={open}
        handleClose={handleClose}
        controlId={controlId}
        inputGroupIdx={inputGroupIdx}
      />
    </>
  );
}
