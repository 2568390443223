import { Box, FormControl } from "@mui/material";
import { InputLabel, Select, MenuItem } from "@mui/material";
import { TextField, Autocomplete } from "@mui/material";
import { ControlInput, IMenuItem } from "../modules/charts/types";

import { aggregationItems } from "../modules/charts/common";

interface ControlInputFieldProps {
  colItems: IMenuItem[];
  input: ControlInput;
  values: any;
  changeValue: (_id: any, _value: any) => void;
  onLabelFocus: () => void;
}

export default function ControlInputField({
  colItems,
  input,
  values,
  changeValue,
  onLabelFocus,
}: ControlInputFieldProps) {
  const { id, type, label, required } = input;
  const menuItems = id === "aggregation" ? aggregationItems : colItems;

  function onChange(e: any) {
    changeValue(id, e.target.value);
  }

  return (
    <Box>
      {type === "select" ? (
        <FormControl sx={{ width: "100%" }}>
          <InputLabel size="small" id={`${id}-label`} required={required}>
            {label}
          </InputLabel>
          <Select
            id={`${id}`}
            labelId={`${id}-label`}
            label={label}
            size="small"
            value={values[id]}
            required={required}
            onChange={onChange}
            fullWidth
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {menuItems.map((menuItem) => (
              <MenuItem key={menuItem.value} value={menuItem.value}>
                {menuItem.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : type === "multi-select" ? (
        <Autocomplete
          multiple
          id={`${id}`}
          size="small"
          options={menuItems}
          getOptionLabel={(option) => option.label}
          filterSelectedOptions
          value={values[id]}
          onChange={(event: any, newValue: any) => {
            changeValue(id, newValue);
          }}
          renderInput={(params) => (
            <TextField required={required} {...params} label={label} />
          )}
        />
      ) : (
        <TextField
          id={`${id}`}
          label={label}
          variant="outlined"
          size="small"
          value={values[id]}
          onChange={onChange}
          onFocus={onLabelFocus}
          required={required}
          fullWidth
        />
      )}
    </Box>
  );
}
