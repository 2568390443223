//hooks
import { useEffect, useMemo, useState } from "react";
import { useDashboardFilter } from "../contexts/DashboardFilterContext";
import { useChartBuilder } from "../contexts/ChartBuilderContext";
import { useFetch } from "../hooks";

//types
import { MouseEvent, ChangeEvent } from "react";
import { AxiosRequestConfig } from "axios";
import { CustomChartProps, RowsPerPageOption } from "../modules/charts/types";
import { TableColumnControlGroupValue } from "../modules/charts/types";
import { Filter, DashboardControls } from "../utils/types";
import { ValueFilter, ValuesFilter, CheckboxFilter } from "../utils/types";

//constants
import { baseUrl } from "../config";

//components
import EChart from "./EChart";
import CustomTable from "./CustomTable";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MoreVertIcon from "@mui/icons-material/MoreVert";

//ui components
import { Box, Stack, IconButton } from "@mui/material";
import { Menu, MenuItem, Typography } from "@mui/material";

type fetchDataResult = {
  rows: any[];
  "total-rows": number;
};

interface ICustomChart {
  chartProps: CustomChartProps;
  dashboardId: number;
  dashboardControls: DashboardControls;
}

export default function CustomChart({
  chartProps,
  dashboardId,
  dashboardControls,
}: ICustomChart) {
  const {
    title,
    datasetId,
    preControls,
    chartConfig,
    optionExtras,
    chartActions,
  } = chartProps;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    chartConfig.defaultRowsPerPage || 50
  );
  const [totalRows, setTotalRows] = useState<number>(0);

  const { filters, appliedFilterValues } = useDashboardFilter();
  const { builder, addPreControlInputGroups, clearControlGroups } =
    useChartBuilder();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage((parseInt(event.target.value) as RowsPerPageOption) || 50);
    setPage(0);
  };

  const fetchDataOptions = useMemo<AxiosRequestConfig | null>(() => {
    const limit = rowsPerPage;
    const offset = page * rowsPerPage;

    const body = {
      dashboard_id: dashboardId,
      filter_column: null,
      filters: filters
        .filter((filter) => {
          if (!(filter.id in appliedFilterValues)) return false;
          if (filter.type === "value") {
            return appliedFilterValues[filter.id] === null ? false : true;
          }
          if (filter.type === "values") {
            return appliedFilterValues[filter.id].length === 0 ? false : true;
          }
          if (filter.type === "checkbox") {
            return appliedFilterValues[filter.id];
          }
          return false;
        })
        .map((filter: Filter) => {
          return filter.type === "value"
            ? filter.getWhereClause(
                filter as ValueFilter,
                appliedFilterValues[filter.id]
              )
            : filter.type === "values"
            ? filter.getWhereClause(
                filter as ValuesFilter,
                appliedFilterValues[filter.id]
              )
            : filter.getWhereClause(
                filter as CheckboxFilter,
                appliedFilterValues[filter.id]
              );
        })
        .filter((whereClause) => whereClause !== null),
      pagination: preControls["table-columns"]
        ? { limit, offset, column: preControls["table-columns"][0].column }
        : null,
    };

    let newDatasetId = datasetId;
    if (dashboardControls["datasetSwitchControl"]) {
      const control = dashboardControls["datasetSwitchControl"];
      newDatasetId = control.value;
    }

    return {
      method: "POST",
      url: `${baseUrl}/explore_chart_data/${newDatasetId}`,
      data: body,
    };
  }, [
    dashboardId,
    datasetId,
    preControls,
    filters,
    appliedFilterValues,
    page,
    rowsPerPage,
    dashboardControls,
  ]);
  const fetchDataObj = useFetch<fetchDataResult>(fetchDataOptions);

  useEffect(() => {
    addPreControlInputGroups(preControls);
    return () => {
      clearControlGroups();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!fetchDataObj.data) return;
    setTotalRows(fetchDataObj.data["total-rows"]);
  }, [fetchDataObj.data]);

  const columns = useMemo<TableColumnControlGroupValue[]>(() => {
    if (!preControls["table-columns"]) return [];
    return preControls["table-columns"];
  }, [preControls]);

  return (
    <Stack
      spacing={0}
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Stack direction="row" px={1} pt={2} justifyContent="space-between">
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <DragIndicatorIcon className="grid-drag-btn" />
          <Typography>{title}</Typography>
        </Stack>
        {chartActions && chartActions.length > 0 && (
          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        )}
      </Stack>
      <Box padding={2} sx={{ width: `100%`, height: `100%` }}>
        {fetchDataObj.error ? (
          <Typography
            sx={{
              justifyContent: "center",
              width: `100%`,
              height: `100%`,
              alignItems: "center",
              display: "flex",
              color: "#343a40",
            }}
          >
            {fetchDataObj.error.message}
          </Typography>
        ) : (
          <Box sx={{ width: `100%`, height: `100%` }}>
            {builder === "echart" && (
              <EChart
                rows={fetchDataObj.data?.rows}
                loading={fetchDataObj.loading}
                chartConfig={chartConfig}
                optionExtras={optionExtras}
              />
            )}
            {builder === "custom-table" && (
              <CustomTable
                rows={fetchDataObj.data?.rows}
                columns={columns}
                loading={fetchDataObj.loading}
                paginationProps={{
                  page,
                  rowsPerPage,
                  totalPages: totalRows,
                  handleChangePage,
                  handleChangeRowsPerPage,
                }}
              />
            )}
          </Box>
        )}
      </Box>
      <Menu
        id="chart-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "chart-menu-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {chartActions?.map((chartAction, idx) => (
          <MenuItem
            key={idx}
            onClick={() => {
              handleClose();
              chartAction.handleClick(
                dashboardId,
                datasetId,
                title,
                filters,
                appliedFilterValues,
                dashboardControls
              );
            }}
          >
            {chartAction.label}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
}
