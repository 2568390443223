import {
  EChartsOption,
  LegendComponentOption,
  XAXisComponentOption,
  YAXisComponentOption,
} from "echarts";
import { AggregationItem, SortType } from "./types";
import { QueryBackend } from "../../utils/types";

export const aggregationItems: AggregationItem[] = [
  { value: "COUNT", label: "COUNT" },
  { value: "SUM", label: "SUM" },
  { value: "AVG", label: "AVG" },
  { value: "MIN", label: "MIN" },
  { value: "MAX", label: "MAX" },
];

export const commonLegend: LegendComponentOption = {
  orient: "horizontal",
  type: "scroll",
  right: 30,
};

export const commonXAxis: XAXisComponentOption = {
  type: "category",
  nameLocation: "middle",
  nameGap: 172,
  axisLabel: {
    rotate: 45,
    margin: 20,
    width: "150",
    overflow: "truncate",
  },
};

export const commonYAxis: YAXisComponentOption = {
  type: "value",
  offset: 20,
  nameLocation: "middle",
  nameGap: 50,
  nameRotate: 90,
};

export function cleanSqlQuery(sql: string): string {
  return sql.replace(/\sLIMIT\s+\d+\s*(;|$)/i, "").replace(/;\s*$/, "");
}

export function getWrapper(backend: QueryBackend) {
  return backend === "mysql" ? "`" : '"';
}

export function sortTimeseriesData(data: any[], sortColumn: string): any[] {
  const newData = data.map((item: any) => ({
    ...item,
    originalDatetime: new Date(item[sortColumn]),
    [sortColumn]: new Date(item[sortColumn]).toLocaleString("default", {
      month: "short",
    }),
  }));

  return newData.sort(
    (a: any, b: any) =>
      a.originalDatetime.getTime() - b.originalDatetime.getTime()
  );
}

export function sortData(
  data: any[],
  sortType: SortType,
  sortColumn: string | undefined
) {
  if (sortType === "none" || !sortColumn) return data;
  if (sortType === "datetime") return sortTimeseriesData(data, sortColumn);
  return data.sort((a, b) =>
    String(a[sortColumn]).localeCompare(String(b[sortColumn]))
  );
}

export const loaderOption: EChartsOption = {
  graphic: {
    elements: [
      {
        type: "group",
        left: "center",
        top: "center",
        children: new Array(3).fill(0).map((val, i) => ({
          type: "rect",
          x: i * 20,
          shape: {
            x: 0,
            y: -40,
            width: 10,
            height: 80,
          },
          style: {
            fill: "#e6292e",
          },
          keyframeAnimation: {
            duration: 1000,
            delay: i * 200,
            loop: true,
            keyframes: [
              {
                percent: 0.5,
                scaleY: 0.3,
                easing: "cubicIn",
              },
              {
                percent: 1,
                scaleY: 1,
                easing: "cubicOut",
              },
            ],
          },
        })),
      },
    ],
  },
};

export const initialChartOption: EChartsOption = {
  graphic: {
    elements: [
      {
        type: "text",
        left: "center",
        top: "200px",
        style: {
          text: "Add required control values to preview chart",
          fontSize: 24,
          lineDash: [0, 200],
          lineDashOffset: 0,
          fill: "#777",
          lineWidth: 1,
        },
      },
      {
        type: "text",
        left: "center",
        top: "230px",
        style: {
          text: "Select values in hightlighted field(s) in the control panel",
          fontSize: 20,
          lineDash: [0, 200],
          lineDashOffset: 0,
          fill: "#777",
          lineWidth: 1,
        },
      },
    ],
  },
};

export const emptyChartOption: EChartsOption = {
  graphic: {
    elements: [
      {
        type: "text",
        left: "center",
        top: "center",
        style: {
          text: "No results were returned for this chart",
          fontSize: 24,
          lineDash: [0, 200],
          lineDashOffset: 0,
          fill: "#777",
          lineWidth: 1,
        },
      },
    ],
  },
};
