export enum Role {
  ROLE_DIRECTORS = "Directors",
  ROLE_RESOURCE_MANAGEMENT_TEAM = "Resource management team",
  ROLE_RESOURCE_MANAGERS = "Resource Manager",
  ROLE_HR_MANAGERS = "HR Manager",
  ROLE_RECRUITMENT_MANAGER = "Recruitment Manager",
  ROLE_RECRUITMENT_STAFF = "Recruitment staff",
  ROLE_HR_STAFFS = "HR Staff",
  ROLE_EXECUTIVES = "Executives",
  ROLE_ACCOUNTS = "Accounts",
  ROLE_DEMAND_REQUEST = "DemandRequest",
  ROLE_PROJECT_MANAGER = "Project Manager",
}

type RequiredRoleFn = (userRoles: Role[], roles: Role[]) => boolean;

export const hasRequiredRole: RequiredRoleFn = (userRoles, roles) =>
  roles.some((role) => userRoles.includes(role));
