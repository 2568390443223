// First we need to import axios.js
import axios from "axios";
import { baseUrl } from "../config";
import { isEmbeddedDashboard } from "./helpers";
import { UserDetails } from "./types";
import useLocalStorage from "../hooks/useLocalStorage";

// Next we make an 'instance' of it
const instance = axios.create({
  // .. where we make our configurations
  baseURL: baseUrl,
  withCredentials: true,
});
instance.defaults.xsrfHeaderName = "X-CSRFToken";
instance.defaults.xsrfCookieName = "csrftoken";
// Where you would set stuff like your 'Authorization' header, etc ...

// Also add/ configure interceptors && all the other cool stuff

instance.interceptors.request.use(
  (request) => {
    const [localUserData] = useLocalStorage<UserDetails | null>(
      "userDetails",
      null
    );

    if (isEmbeddedDashboard()) {
      const [guestToken] = useLocalStorage("guestToken", null);
      request.headers.Authorization = `Bearer ${guestToken}`;
    } else if (!request.headers.Authorization && localUserData) {
      const token = localUserData.access_token;
      if (token) request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
instance.interceptors.response.use(
  async (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const [localUserData, setLocalUserData] =
          useLocalStorage<UserDetails | null>("userDetails", null);

        if (!localUserData) throw new Error("no local user details");

        const refresh_token = localUserData.refresh_token;
        const response = await axios.post(`${baseUrl}/refresh/`, {
          refresh_token,
        });
        // Check if the response status is OK (200)
        if (response.status === 200 && response.data) {
          const token = response.data.access;
          localUserData.access_token = token;
          setLocalUserData(localUserData);

          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return instance(originalRequest);
        } else {
          console.log("Could not generate refresh token, user signed out");
          localStorage.clear();
          if (!!isEmbeddedDashboard()) window.location.href = "/login";
        }
      } catch (error) {
        console.log("Could not generate refresh token, user signed out");
        localStorage.clear();
        if (!isEmbeddedDashboard()) window.location.href = "/login";
        // Handle refresh token error or redirect to login
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
