import React, { createContext, useContext, useEffect, useState } from "react";
import { baseUrl } from "../config";
import axiosConfig from "../utils/axiosConfig";
import { DashboardInfo } from "../utils/types";

// Define the shape of the data context
interface DataContextType {
  serachInput: string;
  dashboardList: DashboardInfo[];
  primaryDashboardList: DashboardInfo[];
  handleSearchInputChange: (event: any) => void;
  loading: boolean;
}

// Create the data context
const DataContext = createContext<DataContextType | null>(null);

// Custom hook to access the data context
export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useData must be used within an DataProvider");
  }
  return context;
};

// DataProvider component to wrap your application with
export const DataProvider = ({ children }: { children: any }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const isApiCallInProgress = React.useRef<boolean>(false);
  const [rowDashboardList, setRowDashboardList] = useState<DashboardInfo[]>([]);
  const [dashboardList, setDashboardList] = useState<DashboardInfo[]>([]);
  const [primaryDashboardList, setPrimaryDashboardList] = useState<
    DashboardInfo[]
  >([]);
  const [serachInput, setSearchInput] = useState<string>("");

  const handleSearchInputChange = (event: any) => {
    const value = event.target.value.toLowerCase();
    setSearchInput(value);
    if (value === "") return setDashboardList(rowDashboardList);
    setDashboardList(
      rowDashboardList.filter(
        ({ title, section }) =>
          title.toLowerCase().includes(value) ||
          section.toLowerCase().includes(value)
      )
    );
  };

  const fetchDashboardList = React.useCallback(() => {
    if (isApiCallInProgress.current) return;
    isApiCallInProgress.current = true;

    // Fetch dashboard list
    axiosConfig({
      method: "get",
      url: `${baseUrl}/dashboard-list/`,
    })
      .then((response) => {
        if (response.status === 200 && response.data) {
          setRowDashboardList(response.data.dashboard);
          setPrimaryDashboardList(response.data.primary_dashboard);
          setLoading(false);
          return response.data;
        } else {
          throw new Error("Failed to fetch the dashboard list.");
        }
      })
      .catch((error) => {
        console.error(error);
        throw new Error("An error occurred while fetching the dashboard list");
      })
      .finally(() => {
        setLoading(false);
        isApiCallInProgress.current = false;
      });
  }, []);

  useEffect(() => {
    fetchDashboardList();
  }, [fetchDashboardList]);

  useEffect(() => {
    setDashboardList(rowDashboardList);
  }, [rowDashboardList]);

  return (
    <DataContext.Provider
      value={{
        serachInput,
        handleSearchInputChange,
        dashboardList,
        primaryDashboardList,
        loading,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
