import React, { useState } from "react";
import "../assets/css/login.css";
import { useGoogleLogin } from "@react-oauth/google";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGoogleAuth } from "../contexts/GoogleAuthContext";

import Footer from "../layouts/Footer";

const GoogleLoginPage: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const { userLogin } = useGoogleAuth();

  const theme = useTheme();
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const { isLoggedIn, errorMessage } = await userLogin(tokenResponse);
      if (errorMessage) setErrorMessage(errorMessage);
      isLoggedIn ? navigate("/") : navigate("/login");
    },
    onError: (error) => {
      console.error("Google Login Error", error);
    },
  });

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            flexGrow: 1,
            height: "calc(100vh - 60px)",
          }}
        >
          <Grid
            item
            xs={6}
            m={3}
            sx={{
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "16px",
            }}
          >
            <img
              src="images/left-side-image.svg"
              alt="login page"
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            ></img>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", flexDirection: "column" }}
            p={3}
            alignItems={"center"}
            justifyContent={"center"}
            pr={12}
          >
            <Typography
              sx={{
                borderRadius: "4px",
                color: "#343A40",
                fontSize: "28px",
                fontWeight: 300,
                px: 1,
                textAlign: "center",
                mb: 2,
              }}
            >
              Welcome to
            </Typography>
            <Grid container justifyContent={"center"} pb={5}>
              <img
                src="images/databoard-icon.svg"
                alt="icon"
                style={{ paddingRight: "16px" }}
              ></img>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                    fontWeight: "700",
                    fontSize: "36px",
                    textAlign: "center",
                    lineHeight: "25px",
                  },
                }}
              >
                QB <span style={{ color: theme.palette.primary.main }}>D</span>
                ataboard
              </Typography>
            </Grid>
            <button onClick={() => login()} className="google-login">
              <img
                src="images/btn_google_signin_light_normal_web@2x 1.svg"
                alt="google login"
                className="icon"
              ></img>
            </button>
            {errorMessage && <div>{errorMessage}</div>}
          </Grid>
        </Grid>
        <Footer />
      </Box>
    </React.Fragment>
  );
};

export default GoogleLoginPage;
