import React from "react";
import { Box, Grid } from "@mui/material";

import Section from "./Section";

export interface ISectionsProps {
  sections: any;
}

export default function Sections({ sections }: ISectionsProps) {
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={2} padding={2} sx={{ flexGrow: 1 }}>
        {Object.keys(sections).map((section) => (
          <Grid item key={section} xs={12} sm={6} md={4} lg={3} xl={2}>
            <Section
              key={section}
              dashboards={sections[section]}
              section={section}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
