import "../assets/css/loader.css";
import * as React from "react";
import { Stack } from "@mui/material";

export default function Loader() {
  return (
    <Stack
      sx={{ width: `100%`, height: `100%` }}
      justifyContent="center"
      alignItems="center"
    >
      <div className="container">
        <div className="line"></div>
      </div>
    </Stack>
  );
}
