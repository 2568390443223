import { CustomDashboardProps } from "../utils/types";

export const dashboard: CustomDashboardProps = {
  title: "Key Metrics Overview",
  charts: [
    {
      id: "1",
      datasetId: "3",
      chartType: "bar",
      title: `Resource Requirement And Hiring Analysis`,
      preControls: {
        "x-axis": [{ column: "stream", label: "stream" }],
        dimension: [{ column: "source", label: "source" }],
        metrics: [
          {
            column: "count",
            aggregation: null,
            label: "count",
          },
        ],
      },
      chartConfig: {
        sortType: "none",
      },
      optionExtras: {
        dataZoom: [
          {
            type: "slider",
            bottom: "40px",
          },
        ],
      },
      layoutId: "1",
      layout: { x: 0, y: 0, w: 8, h: 4, i: "1" },
    },
    {
      id: "2",
      datasetId: "4",
      chartType: "pie",
      title: `Resource Demand by Status`,
      preControls: {
        dimension: [{ column: "status", label: "status" }],
        metrics: [
          {
            column: "num_of_resources",
            aggregation: null,
            label: "num_of_resources",
          },
        ],
      },
      chartConfig: {
        sortType: "none",
      },
      optionExtras: {
        series: { radius: ["30%", "60%"] },
      },
      layoutId: "2",
      layout: { x: 8, y: 0, w: 4, h: 4, i: "2" },
    },
    {
      id: "3",
      datasetId: "5",
      chartType: "pie",
      title: `SalesTracker Opportunities`,
      preControls: {
        dimension: [{ column: "stage_value", label: "stage_value" }],
        metrics: [
          {
            column: "count",
            aggregation: null,
            label: "count",
          },
        ],
      },
      chartConfig: {
        sortType: "none",
      },
      optionExtras: {
        series: { radius: "60%" },
      },
      layoutId: "3",
      layout: { x: 0, y: 4, w: 5, h: 3, i: "3" },
    },
    {
      id: "4",
      datasetId: "2",
      chartType: "line",
      title: `Hired Candidates Trend`,
      preControls: {
        "x-axis": [{ column: "last_modified", label: "last_modified" }],
        metrics: [
          {
            column: "hired",
            aggregation: null,
            label: "hired",
          },
        ],
      },
      chartConfig: {
        sortType: "datetime",
        sortColumn: "last_modified",
      },
      layoutId: "4",
      layout: { x: 5, y: 4, w: 7, h: 3, i: "4" },
    },
  ],
  filters: [],
};
