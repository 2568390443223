import {
  TableCell,
  TableRow,
  tableRowClasses,
  withStyles,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { StyledTableCellProps } from "../types";

const StyledInnerTableRow = styled(TableRow)(({}) => ({
  "& .MuiTableRow-root": {
    height: "36px",
  },
}));

const StyledTableCell = styled(TableCell)<StyledTableCellProps>(
  ({
    theme,
    minWidth = "150px",
    textAlign = "left",
    backgroundColor = "#FFFFFF",
    fontColor = "#343A40",
    hoverBackgroundColor,
    noBorderTop,
    noBorderBottom,
    noBorderRight,
    noBorderLeft,
  }) => ({
    border: `0.5px solid #ECECEC`,
    fontSize: "16px",
    fontWeight: 500,
    borderRight: noBorderRight
      ? "none"
      : `0.5px solid ${theme.palette.divider}`,
    borderTop: noBorderTop ? "none" : `0.5px solid ${theme.palette.divider}`,
    borderBottom: noBorderBottom
      ? "none"
      : `0.5px solid ${theme.palette.divider}`,
    borderLeft: noBorderLeft ? "none" : `0.5px solid ${theme.palette.divider}`,
    color: fontColor,
    minWidth: minWidth,
    textAlign: textAlign,
    backgroundColor: backgroundColor,
    "&:hover": hoverBackgroundColor
      ? {
          backgroundColor: hoverBackgroundColor,
        }
      : {},
  })
);

const StyledTableHeadCell = styled(TableCell)<StyledTableCellProps>(
  ({
    theme,
    textAlign = "center",
    noBorderTop,
    noBorderBottom,
    noBorderRight,
    fontSize,
    fontWeight = 500,
  }) => ({
    borderRight: noBorderRight
      ? "none"
      : `0.5px solid ${theme.palette.divider}`,
    borderTop: noBorderTop ? "none" : `0.5px solid ${theme.palette.divider}`,
    borderBottom: noBorderBottom
      ? "none"
      : `0.5px solid ${theme.palette.divider}`,
    fontSize: fontSize,
    color: "#343A40",
    backgroundColor: "#ECEFF3",
    textAlign: textAlign,
    fontWeight: fontWeight,
  })
);

export default StyledTableHeadCell;

const StickyTableCell = styled(StyledTableCell)({
  padding: "4px 12px 4px 12px",
  position: "sticky",
  left: 0,
  background: "#fff",
  zIndex: 1,
  cursor: "pointer",
});

const StickyTableHeadCell = styled(StyledTableHeadCell)({
  position: "sticky",
  left: 0,
  zIndex: 3,
});

const StickySecondaryTableHeadCell = styled(StyledTableHeadCell)({
  position: "sticky",
  left: 0,
  zIndex: 1,
});

const StyledSecondRowTableHeadCell = styled(StyledTableHeadCell)(
  ({ theme, textAlign = "center" }) => ({
    top: "30px",
  })
);

export {
  StickyTableCell,
  StyledSecondRowTableHeadCell,
  StyledTableHeadCell,
  StickyTableHeadCell,
  StyledTableCell,
  StickySecondaryTableHeadCell,
  StyledInnerTableRow,
};
