import React, { useEffect } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useSqlLab } from "../contexts/SqlLabContext";
import { useToggle } from "../hooks";

interface ColumnSelectorProps {
  databaseId: number | null;
}

export default function ColumnSelector({ databaseId }: ColumnSelectorProps) {
  const {
    schemas,
    tables,
    columns,
    currentSchema,
    currentTable,
    handleDatabaseChange,
    handleSchemaChange,
    handleTableChange,
  } = useSqlLab();

  const schemaMenu = useToggle(false);
  const tableMenu = useToggle(false);

  useEffect(() => {
    handleDatabaseChange(databaseId);
  }, [databaseId, handleDatabaseChange]);

  useEffect(() => {
    function listener(event: any) {
      schemaMenu.handleClose();
      tableMenu.handleClose();
    }

    document.addEventListener("click", listener);

    return () => {
      document.removeEventListener("click", listener);
    };
  });

  return (
    <Box sx={{ p: 2, width: "300px" }}>
      <Typography
        color="text.primary"
        sx={{
          fontSize: "16px",
          fontWeight: 500,
        }}
      >
        Explore Database
      </Typography>
      <FormControl size="small" fullWidth sx={{ mt: 2 }}>
        <InputLabel id="dbname-select-label">Database</InputLabel>
        <Select
          id="dbname-select"
          labelId="dbname-select-label"
          readOnly
          disabled
          value={"QBDWH"}
          label="Database"
        >
          <MenuItem value={"QBDWH"}>{"QBDWH"}</MenuItem>
        </Select>
      </FormControl>
      <FormControl size="small" fullWidth sx={{ mt: 1 }}>
        <InputLabel id="schema-select-label">Schema</InputLabel>
        <Select
          open={schemaMenu.open}
          id="schema-select"
          labelId="schema-select-label"
          value={currentSchema ? currentSchema : ""}
          label="Schema"
          onChange={handleSchemaChange}
          sx={{
            "#menu-": {
              width: "0px",
            },
          }}
          MenuProps={{
            hideBackdrop: true,
            disablePortal: true,
          }}
          onClick={(e) => {
            e.stopPropagation();
            tableMenu.handleClose();
            schemaMenu.toggle();
          }}
        >
          {schemas.length === 0 ? (
            <MenuItem value="">
              <CircularProgress size={16} />
            </MenuItem>
          ) : (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {schemas.map((_schema) => (
            <MenuItem key={_schema} value={_schema ? _schema : ""}>
              {_schema ? _schema : ""}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl size="small" fullWidth sx={{ mt: 1 }}>
        <InputLabel id="table-select-label">Table</InputLabel>
        <Select
          open={tableMenu.open}
          id="table-select"
          labelId="table-select-label"
          value={currentTable ? currentTable : ""}
          label="Table"
          onChange={handleTableChange}
          sx={{
            "#menu-": {
              width: "0px",
            },
          }}
          MenuProps={{
            hideBackdrop: true,
            disablePortal: true,
          }}
          onClick={(e) => {
            e.stopPropagation();
            schemaMenu.handleClose();
            tableMenu.toggle();
          }}
        >
          {currentSchema && tables.length === 0 ? (
            <MenuItem value="">
              <CircularProgress size={16} />
            </MenuItem>
          ) : (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}

          {tables.map((_table) => (
            <MenuItem
              key={_table.value}
              value={_table.value ? _table.value : ""}
            >
              {_table.value ? _table.value : ""}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box sx={{ mt: 2 }}>
        <Typography
          color="text.primary"
          mb={1}
          sx={{
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          Columns
        </Typography>
        {(!currentSchema || !currentTable) && (
          <Typography color="text.secondary">
            Select a schema and a table to list columns
          </Typography>
        )}
        {currentSchema && currentTable && columns.length === 0 && (
          <Typography color="text.secondary">
            <CircularProgress size={16} />
          </Typography>
        )}
        {columns.map((_column) => (
          <Stack
            key={_column.value}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography color="text.secondary" align="left">
              {_column.value}
            </Typography>
            <Typography color="text.secondary" align="right">
              {_column.type}
            </Typography>
          </Stack>
        ))}
      </Box>
    </Box>
  );
}
