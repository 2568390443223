import React from "react";
import { Switch, FormControlLabel, styled } from "@mui/material";
import { ToggleSwitchProps } from "../types";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 26,
  height: 16,
  padding: 0,
  display: "flex",
  "& .MuiSwitch-switchBase": {
    padding: 4,
    "&.Mui-checked": {
      transform: "translateX(10px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#76A4FD",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 8,
    height: 8,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 10,
    opacity: 1,
    backgroundColor: "#E9E9EA",
    boxSizing: "border-box",
  },
}));

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ handleToggle }) => {
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    handleToggle(event.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <CustomSwitch
          checked={checked}
          onChange={handleChange}
          color="primary"
          sx={{ marginLeft: "10px", justifyItems: "center" }}
        />
      }
      label="Month wise view"
      labelPlacement="start"
      sx={{
        marginLeft: 0,
        "& .MuiFormControlLabel-label": {
          fontSize: "14px",
          color: "#343A40",
        },
        alignItems: "center",
      }}
    />
  );
};

export default ToggleSwitch;
