import { columnInputGroup, metricsInputGroup } from "../inputs";
import { EChartsOption } from "echarts";
import { ChartDef, SeriesMap, SeriesMapOption } from "../types";

import ShowChartIcon from "@mui/icons-material/ShowChart";
import { commonLegend, commonXAxis, commonYAxis, sortData } from "../common";

export const lineChartDef: ChartDef = {
  type: "line",
  builder: "echart",
  label: "Line",
  Icon: ShowChartIcon,
  controls: {
    "x-axis": {
      id: "x-axis",
      title: "X Axis",
      inputGroup: columnInputGroup,
      inputGroups: [],
      maxGroups: 1,
      required: true,
    },
    dimension: {
      id: "dimension",
      title: "Dimension",
      inputGroup: columnInputGroup,
      inputGroups: [],
      maxGroups: 1,
      required: false,
    },
    metrics: {
      id: "metrics",
      title: "Metrics",
      inputGroup: metricsInputGroup,
      inputGroups: [],
      maxGroups: 1,
      required: true,
    },
  },
  buildChartOption(controlLabels, rows, chartConfig, optionExtras) {
    const { xAxisLabel, seriesLabels, metricsLabels } = controlLabels;
    const { sortType, sortColumn } = chartConfig;

    const sortedRows = sortData(rows, sortType, sortColumn);

    const seriesMap: SeriesMap = {};
    const xAxisData = xAxisLabel
      ? Array.from(new Set(sortedRows.map((row) => row[xAxisLabel!] ?? "NA")))
      : [];

    sortedRows.forEach((row) => {
      const seriesKey: string | number =
        seriesLabels.length > 0
          ? row[seriesLabels[0]] ?? "NA"
          : metricsLabels[0] ?? "NA";

      if (!(seriesKey in seriesMap)) {
        const seriesOption: SeriesMapOption = {
          data: [],
          type: "line",
          stack: "total",
          name: seriesKey,
          emphasis: {
            focus: "series",
          },
          ...(optionExtras?.series || {}),
        };
        seriesMap[seriesKey] = seriesOption;
      }

      let dataIdx: null | number = null;
      if (xAxisLabel)
        dataIdx = xAxisData.findIndex((el: string) => el === row[xAxisLabel!]);
      dataIdx = dataIdx !== null && dataIdx >= 0 ? dataIdx : 0;
      seriesMap[seriesKey].data[dataIdx] = row[metricsLabels[0]] ?? 0;
    });

    const xAxis = {
      ...commonXAxis,
      name: xAxisLabel || "X Axis",
      data: xAxisData,
    };

    const series = Object.values(seriesMap);

    const option: EChartsOption = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: { ...commonLegend },
      yAxis: {
        ...commonYAxis,
        name: metricsLabels[0] || "Metrics",
      },
      series: series as any,
      ...(optionExtras?.dataZoom ? { dataZoom: optionExtras.dataZoom } : {}),
    };

    if (xAxisLabel) option.xAxis = xAxis;

    return option;
  },
};
