import { tableColumnInputGroup } from "../inputs";
import { ChartDef } from "../types";
import TableChartRoundedIcon from "@mui/icons-material/TableChartRounded";

export const tableChartDef: ChartDef = {
  type: "table",
  builder: "custom-table",
  label: "Table",
  Icon: TableChartRoundedIcon,
  controls: {
    "table-columns": {
      id: "table-columns",
      title: "Columns",
      inputGroup: tableColumnInputGroup,
      inputGroups: [],
      maxGroups: 4,
      required: true,
    },
  },
};
