import { CustomDashboardProps } from "../utils/types";

import { dashboard as keyMetricsOverview } from "./key-metrics-overview";
import { dashboard as projectWiseAllocations } from "./project-wise-allocations";
import { dashboard as evaConcernsTend } from "./eva-concerns-trend";
import { dashboard as evaTends } from "./eva-trends";
import { dashboard as grossProfitComparison } from "./gross-profit-comparison";
import { dashboard as financialAnalysis } from "./financial-analysis";

type DashboardObj = {
  [key: number]: CustomDashboardProps;
};

export const customDashboards: DashboardObj = {
  15: keyMetricsOverview,
  18: projectWiseAllocations,
  20: evaConcernsTend,
  21: evaTends,
  24: grossProfitComparison,
  25: financialAnalysis,
};
