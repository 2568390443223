import React from "react";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

interface Item {
  name: string;
  handleClick?: () => void;
}

interface MenuList {
  icon?: string;
  title: string;
  items: Item[];
}

interface MultiMenuProps {
  list: MenuList;
}

const MultiMenu = ({ list }: MultiMenuProps) => {
  const theme = useTheme();
  const { icon, title, items } = list;
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ListItem
        onClick={handleClose}
        sx={{
          ":hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.grey[200],
          },
        }}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={title} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((_item, key) => (
            <Stack
              key={key}
              onClick={_item.handleClick}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                px: 2,
                pl: 4,
                height: "44px",
                minWidth: "200px",
                ":hover": {
                  cursor: "pointer",
                  backgroundColor: theme.palette.grey[200],
                },
              }}
            >
              <ListItemText sx={{ fontSize: "14px" }}>
                {_item.name}
              </ListItemText>
              <AddCircleOutlineOutlinedIcon sx={{ ml: 2, fontSize: "14px" }} />
            </Stack>
          ))}
        </List>
      </Collapse>
      <Divider />
    </React.Fragment>
  );
};

export default MultiMenu;
