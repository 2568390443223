import React, { useState, MouseEvent, useEffect } from "react";
import {
  Menu,
  MenuItem,
  TextField,
  Autocomplete,
  Box,
  Typography,
  ListItem,
  styled,
  Popper,
} from "@mui/material";
import {
  CustomerList,
  Option,
  ProjectList,
  TwoLevelDropdownProps,
} from "../types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRightOutlined";
import { AxiosRequestConfig } from "axios";
import { baseUrl } from "../../../config";
import useFetch from "../../../hooks/useFetch";
import { useApp } from "../../../contexts/AppContext";
import { reportType } from "../constants";

const optionsLevel1 = [
  { label: reportType.overview, value: reportType.overview },
  { label: reportType.customer, value: reportType.customer },
  { label: reportType.project, value: reportType.project },
];

const MultiLevelDropdown: React.FC<TwoLevelDropdownProps> = ({
  placeholder,
  selectedValue,
  disabled = false,
  error = null,
  setSelectedValue,
  selectedLevel1,
  setSelectedLevel1,
  setSelectedLevel2,
  dashboardId,
}) => {
  const { selectedFilter } = useApp();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElLevel2, setAnchorElLevel2] = useState<null | HTMLElement>(
    null
  );
  const [isSearchActive, setSearchActive] = useState(false);

  const fetchProjectList = React.useMemo<AxiosRequestConfig | null>(() => {
    return {
      method: "GET",
      url: `${baseUrl}/projects?dashboard_id=${dashboardId}`,
    };
  }, [dashboardId]);

  const projectData = useFetch<{
    data: ProjectList;
    message: string;
  } | null>(fetchProjectList);

  const fetchCustomerList = React.useMemo<AxiosRequestConfig | null>(() => {
    return {
      method: "GET",
      url: `${baseUrl}/customers?dashboard_id=${dashboardId}`,
    };
  }, [dashboardId]);

  const customerData = useFetch<CustomerList | null>(fetchCustomerList);

  const projectList: Option[] =
    projectData.data?.data.map((project) => ({
      label: project.name,
      value: project.project_id,
    })) || [];

  const customerList: Option[] =
    customerData.data?.data.map((customer) => ({
      label: customer.customer_name,
      value: "" + customer.customer_id,
    })) || [];

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (closeMainDropDown?: boolean) => {
    if (closeMainDropDown) {
      setAnchorEl(null);
    }
    setSearchActive(false);
    setAnchorElLevel2(null);
  };

  const handleLevel1Click = (
    event: MouseEvent<HTMLElement>,
    option: Option
  ) => {
    setSelectedLevel1(option);
    setSelectedLevel2(null);
    setSelectedValue("");
    if (
      option.value === reportType.customer ||
      option.value === reportType.project
    ) {
      setAnchorElLevel2(event.currentTarget);
      setTimeout(() => setSearchActive(true), 300);
    } else {
      handleClose(true);
    }
  };

  const getOptionsForSelectedLevel1 = () => {
    if (selectedLevel1?.value === reportType.customer) {
      return customerList;
    }
    if (selectedLevel1?.value === reportType.project) {
      return projectList;
    }
    return [];
  };

  const StyledPopper = styled(Popper)(({ theme }) => ({
    ".MuiAutocomplete-listbox": {
      maxHeight: "300px",
      overflowY: "auto",
    },
  }));

  const setInitialData = () => {
    setSelectedLevel1(null);
    setSelectedLevel2(null);
    setSelectedValue("");
  };

  useEffect(() => {
    if (!selectedFilter.selectedLevel1 && !selectedFilter.selectedLevel2) {
      setSelectedLevel1(null);
      setSelectedLevel2(null);
      setSelectedValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter.selectedLevel1, selectedFilter.selectedLevel2]);

  useEffect(() => {
    setInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Box
        onClick={handleClick}
        sx={{
          position: "relative",
          width: "126px",
          height: "40px !important",
          border: "1px solid #DADADA",
          borderRadius: "4px",
          paddingLeft: "12px",
          paddingRight: "12px",
          cursor: `${disabled ? "not-allowed" : "pointer"}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "&:hover": {
            border: "1px solid #000000",
          },
        }}
      >
        <Typography
          sx={{ color: "#343A40", fontSize: "14px", fontWeight: "400" }}
        >
          {selectedLevel1?.label || placeholder}
        </Typography>
        <ExpandMoreIcon sx={{ color: "#343A40" }} />
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose(true)}
        PaperProps={{
          style: {
            width: "150px",
            cursor: `${disabled ? "not-allowed" : "pointer"}`,
          },
        }}
      >
        {optionsLevel1.map((option, index) => (
          <MenuItem
            disabled={disabled}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "6px",
              paddingRight: "2px",
              alignItems: "center",
              fontSize: "14px",
              color: "#000000",
              fontWeight: 400,
              paddingY: "8px",
              borderBottom:
                index !== optionsLevel1.length - 1 ? "1px solid #E6E6E6" : "",
            }}
            key={option.value}
            onClick={(event) => handleLevel1Click(event, option)}
          >
            {option.label}
            {option.label !== reportType.overview ? (
              <ChevronRightIcon
                sx={{ width: "16px", height: "16px", padding: 0 }}
              />
            ) : (
              ""
            )}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        id="level2-menu"
        anchorEl={anchorElLevel2}
        open={Boolean(anchorElLevel2)}
        onClose={() => handleClose()}
        PaperProps={{
          style: {
            marginTop: 0,
            marginLeft: "2px",
            width: "150px",
            maxHeight: "200px",
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Autocomplete
          open={isSearchActive}
          sx={{ border: "none" }}
          options={getOptionsForSelectedLevel1()}
          popupIcon={false}
          clearIcon={false}
          color="#000000"
          getOptionLabel={(option) => "" + option.label}
          value={
            getOptionsForSelectedLevel1().find(
              (option) => option.value === selectedValue
            ) || null
          }
          onChange={(event, newValue) => {
            if (newValue) {
              setSelectedValue(newValue.value || "");
              setSelectedLevel2(newValue);
              handleClose(true);
            } else {
              setSelectedValue("");
            }
          }}
          autoComplete={true}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder={
                selectedLevel1?.label === reportType.customer
                  ? "Search Customer"
                  : "Search Project"
              }
              size="small"
              error={Boolean(error)}
              sx={{
                "& .MuiInputBase-root": {
                  px: 1,
                  paddingRight: "0 !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottom: "none",
                },
              }}
            />
          )}
          renderOption={(props, option, { selected }) => {
            return (
              <ListItem
                sx={{
                  borderBottom: "1px solid #E6E6E6",
                  "&:first-of-type": {
                    borderTop: "1px solid #E6E6E6",
                  },
                  "&:last-child": {
                    borderBottom: "none",
                  },
                }}
                key={option.value}
                {...props}
              >
                {option.label}
              </ListItem>
            );
          }}
          PopperComponent={StyledPopper}
        />
      </Menu>
    </div>
  );
};

export default MultiLevelDropdown;
