type SetValue<T> = (value: T) => void;

function useLocalStorage<T>(key: string, initialValue: T): [T, SetValue<T>] {
  // Get initial value from localStorage or use the provided initialValue
  const storedValue = localStorage.getItem(key);
  const initial = storedValue ? JSON.parse(storedValue) : initialValue;

  // Update localStorage whenever the value changes
  const updateValue: SetValue<T> = (newValue) => {
    localStorage.setItem(key, JSON.stringify(newValue));
  };

  updateValue(initial);

  return [initial, updateValue];
}

export default useLocalStorage;
