import { columnInputGroup, metricsInputGroup } from "../inputs";
import { EChartsOption, PieSeriesOption } from "echarts";
import { ChartDef } from "../types";

import PieChartIcon from "@mui/icons-material/PieChart";
import { commonLegend, sortData } from "../common";

export const pieChartDef: ChartDef = {
  type: "pie",
  builder: "echart",
  label: "Pie",
  Icon: PieChartIcon,
  controls: {
    dimension: {
      id: "dimension",
      title: "Dimension",
      inputGroup: columnInputGroup,
      inputGroups: [],
      maxGroups: 1,
      required: true,
    },
    metrics: {
      id: "metrics",
      title: "Metrics",
      inputGroup: metricsInputGroup,
      inputGroups: [],
      maxGroups: 1,
      required: true,
    },
  },
  buildChartOption(controlLabels, rows, chartConfig, optionExtras) {
    const { seriesLabels, metricsLabels } = controlLabels;
    const { sortType, sortColumn } = chartConfig;

    const sortedRows = sortData(rows, sortType, sortColumn);

    const seriesOption: PieSeriesOption = {
      data: sortedRows.map((row) => ({
        value: row[metricsLabels[0]],
        name: row[seriesLabels[0]] || "NA",
      })),
      type: "pie",
      emphasis: {
        focus: "series",
      },
      ...(optionExtras?.series || {}),
    };

    const option: EChartsOption = {
      tooltip: {
        trigger: "item",
        formatter(param: any) {
          return param.name + ": " + param.value + " (" + param.percent + "%)";
        },
      },
      legend: { ...commonLegend },
      series: [seriesOption],
    };

    return option;
  },
};
