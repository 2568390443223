import {
  AggControlInput,
  ColumnControlInput,
  ColumnControlInputGroup,
  LabelControlInput,
  MetricsControlInputGroup,
  ParentColumnsControlInput,
  TableColumnControlInputGroup,
  WidthControlInput,
} from "./types";

const columnInput: ColumnControlInput = {
  id: "column",
  type: "select",
  label: "Column",
  required: true,
  value: "",
};

const labelInput: LabelControlInput = {
  id: "label",
  type: "text",
  label: "Label",
  required: true,
  value: "",
};

const metricsAggInput: AggControlInput = {
  id: "aggregation",
  type: "select",
  label: "Aggregation",
  required: false,
  value: null,
};

const parentColumnsInput: ParentColumnsControlInput = {
  id: "parentColumns",
  type: "multi-select",
  label: "Parent Columns",
  required: false,
  value: [],
};

const widthInput: WidthControlInput = {
  id: "width",
  type: "number",
  label: "Width",
  required: false,
  value: 150,
};

export const columnInputGroup: ColumnControlInputGroup = {
  groupType: "column",
  addTo: ["SELECT", "GROUP-BY"],
  inputs: {
    column: columnInput,
    label: labelInput,
  },
};

export const metricsInputGroup: MetricsControlInputGroup = {
  groupType: "metrics",
  addTo: ["SELECT"],
  inputs: {
    aggregation: metricsAggInput,
    column: columnInput,
    label: labelInput,
  },
};

export const tableColumnInputGroup: TableColumnControlInputGroup = {
  groupType: "table-column",
  addTo: ["SELECT", "ORDER-BY"],
  inputs: {
    column: columnInput,
    label: labelInput,
    parentColumns: parentColumnsInput,
    width: widthInput,
  },
};
