import { useRouteError } from "react-router-dom";
import { FC } from "react"; // Import FC type from React

interface ErrorPageProps {
  // Define props if needed
}

const ErrorPage: FC<ErrorPageProps> = () => {
  const error: any = useRouteError(); // Define the type for the error
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
};

export default ErrorPage;
