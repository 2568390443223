import { CustomDashboardProps } from "../utils/types";
import FinancialAnalysisDashboard from "../components/FinancialAnalysis";

export const dashboard: CustomDashboardProps = {
  title: "Financial Analysis",
  charts: [],
  filters: [],
  Component: FinancialAnalysisDashboard,
  showPreviousYearMenu: true,
  showMonthWiseToggle:true,
};
