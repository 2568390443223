import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    text: {
      primary: "#343a40",
      secondary: "#71767a",
    },
    primary: {
      light: "#eb5357",
      main: "#e6292e",
      dark: "#a11c20",
      contrastText: "#fff",
    },
    secondary: {
      light: "#55d6f4",
      main: "#32cdef",
      dark: "#20c4ea",
      contrastText: "#000",
    },
    error: {
      main: "#e6292e",
    },
  },
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
  },
});

export default theme;
