import Dashboard from "./Dashboard";
import Loading from "../components/Loading";

import { useGoogleAuth } from "../contexts/GoogleAuthContext";
import { DataProvider } from "../contexts/DataContext";

export default function EmbeddedDashboard() {
  const { guestToken } = useGoogleAuth();

  return (
    <>
      {guestToken ? (
        <DataProvider>
          <Dashboard isEmbedded={true} />
        </DataProvider>
      ) : (
        <Loading open={guestToken ? false : true} />
      )}
    </>
  );
}
