import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ControlInputForm from "./ControlInputForm";
import { IconButton, Stack } from "@mui/material";
import { useChartBuilder } from "../contexts/ChartBuilderContext";
import { ControlId, IMenuItem } from "../modules/charts/types";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  p: 4,
};

interface IControlInputModal {
  colItems: IMenuItem[];
  open: boolean;
  handleClose: () => void;
  controlId: ControlId;
  inputGroupIdx: number;
}

export default function ControlInputModal({
  colItems,
  open,
  handleClose,
  controlId,
  inputGroupIdx,
}: IControlInputModal) {
  const { controls } = useChartBuilder();
  const control = controls[controlId]!;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack direction="row" justifyContent="space-between">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {control.title}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <ControlInputForm
          colItems={colItems}
          controlId={controlId}
          inputGroupIdx={inputGroupIdx}
          handleClose={handleClose}
        ></ControlInputForm>
      </Box>
    </Modal>
  );
}
